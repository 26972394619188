<template>
  <div id="table-panel">
    <div class="demoBox"></div>
    <!-- 表格组件 -->
    <div class="content-main"  v-loading="pictLoading">
      <!-- 骨架屏 -->
      <Wang-skeleton
        type="line"
        :options="skeletonOptions"
        v-if="pageNum === 1"
      >
      </Wang-skeleton>
      <YiScrollbar class="myDiv" :dataList="dataList" ref="yiScrollbar">
        <div class="myTable scrollBoxUnique" ref="tableBox">
          <cc-table
            ref="tablePanel"
            id="table"
            :data="dataList"
            noThrottle
            isHoverRow
            v-show="pageNum !== 1"
            :border="border"
            :row-class-name="rowClassName"
            :span-method="spanMethod"
            aria-activedescendant=""
            :header-row-class-name="headerRowClassName"
            cell-class-name="cellClassName"
            @cell-mouse-enter="cellMouseEnter"
            @header-dragend="headerDragend"
            @cell-mouse-leave="cellMouseLeave"
            @select-all="selectAll"
            @selection-change="selectionChange"
            @select="select"
            @row-click="rowClick"
            @cell-click="changLine"
            @cell-dblclick="cellDoubleClick"
            :empty-text="$t('label.dashboard.noRecord')"
            :stripe="stripe"
            :style="{
              width: '100%',
              'min-height': minHeight ? '418px' : '0px',
            }"
          >
            <!-- 最左侧操作列 -->
            <cc-table-column
              v-if="showTableHeadTool"
              align="right"
              :resizable="false"
              fixed
              width="26"
            >
              <!-- 自定义表头部分 -->
              <template #header>
                <div>
                  <el-dropdown
                    trigger="click"
                    @command="handleCommand"
                    placement="bottom-start"
                    @visible-change="DropChange"
                  >
                    <!-- 管理图标 -->
                    <svg
                      class="icon admin_s"
                      aria-hidden="true"
                      @mouseleave="outSvg(1)"
                      @mouseover="overSvg(1)"
                    >
                      <use :href="svgIcon"></use>
                    </svg>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="1"
                        v-if="
                          pageNum !== 1 &&
                          showTableHeadTool &&
                          viewModify === 'true'
                        "
                      >
                        <!-- 管理列 -->
                        {{ $t("c127") }}
                      </el-dropdown-item>
                      <el-dropdown-item :command="2">
                        <!-- 重置列宽 -->
                        {{ $t("vue_label_commonobjects_view_reset_width") }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
              <!-- 内容 -->
              <template slot-scope="scope">
                <div
                  v-show="
                    tableButtonData.buttonList &&
                    tableButtonData.buttonList.length &&
                    scope.row.isEnter
                  "
                >
                  <el-popover
                    placement="bottom-start"
                    popper-class="pop_small_edit"
                    trigger="click"
                    :visible-arrow="false"
                    v-show="objModifyAll || objModify || objDelete"
                  >
                    <svg
                      slot="reference"
                      class="icon tableButton"
                      aria-hidden="true"
                      @mouseout="outSvg(2)"
                      @mouseover="overSvg(2)"
                      @click="handleclick(scope.row)"
                    >
                      <use :href="svgIcon1"></use>
                    </svg>
                    <!-- 操作项 -->
                    <ul>
                      <li
                        class="liBox"
                        v-for="(btnItem, index) in tableButtonData.buttonList"
                        :key="btnItem.action"
                        v-show="ifButtonShow(scope, btnItem, tableButtonData)"
                        @click="handleTableAction(scope, btnItem)"
                      >
                        <span
                          class="cursor-pointer"
                          :disabled="btnItem.disabled"
                          :key="index"
                        >
                          {{ btnItem.label }}
                        </span>
                      </li>
                    </ul>
                  </el-popover>
                </div>
              </template>
            </cc-table-column>
            <!-- 多选框和序号列 -->
            <cc-table-column
              v-if="checked"
              type="checkbox"
              align="center"
              :resizable="false"
              :selectable="checkboxSelect"
              fixed
              width="54"
            >
            </cc-table-column>
            <!-- 表头&内容展示区 -->
            <template v-for="(item, itemIndex) in tableAttr">
              <slot :name="item.schemefieldName" :item="item">
                <!-- html展示数据，一般用于富文本框 -->
                <cc-table-column
                  v-if="
                    !item.click &&
                    !item.options &&
                    !item.isInput &&
                    (item.ishtml ||
                      (item.schemefieldType === 'Z' &&
                        item.expressionType === 'S'))
                  "
                  :align="item.align ? item.align : 'left'"
                  :label="item.nameLabel"
                  :width="item.colwidth ? item.colwidth : ''"
                  :prop="item.schemefieldName"
                  :fixed="item.fixed"
                  :sortable="item.sortable"
                  :show-overflow-tooltip="true"
                >
                  <!-- 自定义表头部分 -->
                  <template #header v-if="customHeader">
                    <!-- 自定义表头信息 -->
                    <div
                      class="tableHeadField"
                      @mouseout="outSvg(3, item)"
                      @mouseover="overSvg(3, item)"
                      @click="changeSortFiled($event, item)"
                    >
                      <!-- 表头内容展示 -->
                      <el-popover
                        placement="top-start"
                        :open-delay="openDelay"
                        trigger="hover"
                        popper-class="my-tooltip table-head-tooltip"
                        :content="nameLableList[itemIndex]"
                      >
                        <span slot="reference">
                          {{ nameLableList[itemIndex] }}
                        </span>
                      </el-popover>
                      <!-- 表头排序时图标 -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        :style="{
                          transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                        }"
                        v-show="sortDir && sortField === item.apiname"
                      >
                        <use href="#icon-a-Ascendingorder"></use>
                      </svg>
                      <!-- 表头排序未选中，上浮样式 -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        v-show="
                          isSort(item) &&
                          item.id === hoveHeaID &&
                          !(sortDir && sortField === item.apiname)
                        "
                      >
                        <use href="#icon-a-Nosort"></use>
                      </svg>
                      <!-- 表头右侧svg 锁定列 未锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-if="showLock"
                        v-show="item.id === hoveHeaID && !item.locked"
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-222"></use>
                        </svg>
                      </span>
                      <!-- 锁定列,鼠标上浮才显示 锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-show="
                          item.id === hoveHeaID && item.locked && showLock
                        "
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-111"></use>
                        </svg>
                      </span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div v-html="scope.row[item.schemefieldName]"></div>
                  </template>
                </cc-table-column>
                <!-- 普通文本类型数据 -->
                <cc-table-column
                  v-else-if="
                    !item.click &&
                    !item.options &&
                    !item.isInput &&
                    !item.ishtml
                  "
                  :align="item.align ? item.align : 'left'"
                  :width="
                    item.colwidth
                      ? item.colwidth
                      : item.schemefieldType === 'D' ||
                        item.schemefieldType === 'F' ||
                        item.schemefieldType === 'c' ||
                        item.schemefieldType === 'N' ||
                        item.schemefieldType === 'T'
                      ? '175'
                      : ''
                  "
                  :min-width="
                    item.schemefieldType === 'D' ||
                    item.schemefieldType === 'F' ||
                    item.schemefieldType === 'c' ||
                    item.schemefieldType === 'N' ||
                    item.schemefieldType === 'T'
                      ? '175'
                      : ''
                  "
                  :label="item.nameLabel"
                  :prop="item.schemefieldName"
                  :fixed="item.fixed"
                  :sortable="item.sortable"
                  :show-overflow-tooltip="
                    item.schemefieldType !== 'B' && item.schemefieldType !== 'X'
                  "
                >
                  <template #header v-if="customHeader">
                    <div
                      class="tableHeadField"
                      @mouseout="outSvg(3, item)"
                      @mouseover="overSvg(3, item)"
                      @click="changeSortFiled($event, item)"
                    >
                      <!-- 表头名称 -->
                      <el-popover
                        placement="top-start"
                        :open-delay="openDelay"
                        trigger="hover"
                        popper-class="my-tooltip table-head-tooltip"
                        :content="nameLableList[itemIndex]"
                      >
                        <span slot="reference">
                          {{ nameLableList[itemIndex] }}
                        </span>
                      </el-popover>
                      <!-- 表头排序svg -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        :style="{
                          transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                        }"
                        v-show="sortDir && sortField === item.apiname"
                      >
                        <use href="#icon-a-Ascendingorder"></use>
                      </svg>
                      <!-- 未选中，上浮样式 -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        v-show="
                          isSort(item) &&
                          item.id === hoveHeaID &&
                          !(sortDir && sortField === item.apiname)
                        "
                      >
                        <use href="#icon-a-Nosort"></use>
                      </svg>
                      <!-- 表头右侧svg 锁定列 未锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-if="showLock"
                        v-show="item.id === hoveHeaID && !item.locked"
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-222"></use>
                        </svg>
                      </span>
                      <!-- 锁定列 锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-show="
                          item.id === hoveHeaID && item.locked && showLock
                        "
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-111"></use>
                        </svg>
                      </span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <span
                      :style="{
                        'text-align':
                          (item.schemefieldType === 'N' &&
                            item.schemefieldName !== 'leadscore') ||
                          item.schemefieldType === 'c' ||
                          item.schemefieldType === 'P' ||
                          (item.schemefieldType === 'Z' &&
                            (item.expressionType == 'N' ||
                              item.expressionType == 'P' ||
                              item.expressionType == 'c')) ||
                          item.schemefieldType === 'C'
                            ? 'right'
                            : 'left',
                      }"
                    >
                      <!-- 日期 -->
                      <span
                        class="cannotEdit"
                        v-if="item.schemefieldType === 'D'"
                      >
                        {{
                          scope.row[item.schemefieldName]
                            | dateFormat(countryCode)
                        }}
                      </span>
                      <!-- 日期时间 -->
                      <span
                        class="cannotEdit"
                        v-else-if="item.schemefieldType === 'F'"
                      >
                        {{
                          scope.row[item.schemefieldName]
                            | datetimeFormat(countryCode)
                        }}
                      </span>
                      <!-- 记录类型 -->
                      <span
                        class="cannotEdit"
                        v-else-if="item.schemefieldType === 'R'"
                      >
                        {{ scope.row[`${item.schemefieldName}ccname`] }}
                      </span>
                      <!-- 公式-复选框字段 -->
                      <!-- 复选框 -->
                      <span
                        class="cannotEdit"
                        v-else-if="
                          item.schemefieldType === 'B' ||
                          (item.schemefieldType === 'Z' &&
                            item.expressionType === 'B')
                        "
                      >
                        <el-checkbox
                          :value="
                            scope.row[item.schemefieldName] === 'true' ||
                            scope.row[item.schemefieldName] === '1'
                          "
                          disabled
                        >
                        </el-checkbox>
                      </span>
                      <!-- URL -->
                      <span
                        class="cursor-pointer allow-click"
                        v-else-if="item.schemefieldType === 'U'"
                        @click="jumpToUrl(scope.row[item.schemefieldName])"
                      >
                        {{ scope.row[item.schemefieldName] }}
                      </span>
                      <!-- 标签 -->
                      <span
                        class="cannotEdit"
                        v-else-if="
                          item.schemefieldType === 'X' &&
                          item.schemefieldName === 'cloudcctag'
                        "
                      >
                        <el-tooltip
                          :content="
                            scope.row['cloudcctagList']
                              ? scope.row['cloudcctagList']
                                  .map((res) => res.name)
                                  .join(' ')
                              : ''
                          "
                          placement="top-start"
                          :visible-arrow="false"
                        >
                          <span>
                            <el-tag
                              v-for="tag in scope.row['cloudcctagList']"
                              :key="tag.id"
                              :type="
                                tag.color === 'hui'
                                  ? 'info'
                                  : tag.color === 'hong'
                                  ? 'danger'
                                  : tag.color === 'lv'
                                  ? 'success'
                                  : tag.color === 'lan'
                                  ? ''
                                  : tag.color === 'huang'
                                  ? 'warning'
                                  : ''
                              "
                              effect="dark"
                              class="tag"
                            >
                              {{ tag.name }}
                            </el-tag>
                          </span>
                        </el-tooltip>
                      </span>
                      <!-- 图片 -->
                      <span
                        v-else-if="
                          item.schemefieldType === 'IMG' &&
                          item.expressionType !== 'url'
                        "
                        style="width: 100%; display: inlne-block"
                      >
                        <el-popover placement="right" title="" trigger="hover">
                          <img
                            :src="imgSrc(scope.row[item.schemefieldName])"
                            style="max-height: 200px; maxwidth: 200px"
                          />

                          <img
                            v-if="
                              scope.row[item.schemefieldName] !== undefined &&
                              scope.row[item.schemefieldName] !== '' &&
                              scope.row[item.schemefieldName] !== null
                            "
                            slot="reference"
                            :src="imgSrc(scope.row[item.schemefieldName])"
                            :alt="imgSrc(scope.row[item.schemefieldName])"
                            style="height: 16px; width: 16px"
                          />
                        </el-popover>
                      </span>
                      <!-- 图片 引用类型 -->
                      <span
                        v-else-if="
                          item.schemefieldType === 'IMG' &&
                          item.expressionType === 'url'
                        "
                      >
                        <el-popover placement="right" title="" trigger="hover">
                          <img
                            :src="scope.row[item.schemefieldName]"
                            style="max-height: 200px; max-width: 200px"
                          />
                          <img
                            v-if="
                              scope.row[item.schemefieldName] !== undefined &&
                              scope.row[item.schemefieldName] !== '' &&
                              scope.row[item.schemefieldName] !== null
                            "
                            slot="reference"
                            :src="scope.row[item.schemefieldName]"
                            :alt="scope.row[item.schemefieldName]"
                            style="height: 16px; width: 16px"
                          />
                        </el-popover>
                      </span>
                      <!-- 评分 -->
                      <span
                        v-else-if="item.schemefieldType === 'SCORE'"
                        class="cannotEdit stars"
                      >
                        <span
                          v-for="(star, idx) in Number(item.schemefieldLength) +
                          1"
                          v-show="idx !== 0"
                          :class="{
                            show2:
                              idx <= Number(scope.row[item.schemefieldName]),
                          }"
                          :key="idx"
                        >
                        </span>
                      </span>
                      <!-- 潜在客户打分 -->
                      <span
                        v-else-if="item.schemefieldName === 'leadscore'"
                        style="display: block; padding-right: 7px"
                        class="cannotEdit"
                      >
                        <span style="width: 25px; display: inline-block">{{
                          scope.row[item.schemefieldName]
                        }}</span>
                        <!-- 进度条percentage值为0到100 -->
                        <el-progress
                          :percentage="
                            scope.row[item.schemefieldName] !== undefined &&
                            scope.row[item.schemefieldName] !== null
                              ? Math.abs(
                                  Number(scope.row[item.schemefieldName])
                                )
                              : 0
                          "
                          :color="customColors"
                          :format="formatProgress"
                        >
                        </el-progress>
                      </span>
                      <!-- 文件 -->
                      <span
                        v-else-if="item.schemefieldType === 'FL'"
                        class="cannotEdit"
                      >
                        <span
                          v-for="file in scope.row[
                            item.schemefieldName + 'List'
                          ]"
                          :key="file.fileid"
                          class="fileItem"
                          @click="downLoadFile(file)"
                        >
                          {{ file.filename || file.name }}
                        </span>
                      </span>
                      <!-- 任务及事件下的名称对象及相关对象 -->
                      <span
                        v-else-if="
                          item.schemefieldName === 'relateobj' ||
                          item.schemefieldName === 'whoobj'
                        "
                        class="cannotEdit"
                      >
                        <span>
                          {{
                            scope.row[`${item.schemefieldName}`] === "null"
                              ? ""
                              : scope.row[`${item.schemefieldName}`]
                          }}
                        </span>
                      </span>
                      <!-- 相关列表字段跟踪、操作operate -->
                      <span
                        v-else-if="
                          item.schemefieldName === 'operate' ||
                          item.schemefieldName === 'clxx'
                        "
                        class="cannotEdit"
                        v-html="scope.row[item.schemefieldName]"
                      >
                      </span>
                      <!-- 处理富文本展示 -->
                      <span
                        v-else-if="
                          item.schemefieldType == 'Z' &&
                          item.expressionType == 'S'
                        "
                        class="cannotEdit"
                        v-html="scope.row[item.schemefieldName]"
                      >
                      </span>
                      <!-- 普通文本 -->
                      <span class="cannotEdit" v-else>
                        {{ scope.row[item.schemefieldName] }}
                      </span>
                      <!--事件及任务下的名称及相关项去除编辑/相关列表文件、类型attachtype去除编辑-->
                      <svg
                        v-if="
                          item.schemefieldName !== 'relateobj' &&
                          item.schemefieldName !== 'whoobj' &&
                          item.schfemefieldName !== 'attachtype'
                        "
                        class="icon editIconImg"
                        aria-hidden="true"
                        style="display: none"
                        @click="editCell(item, scope.row)"
                      >
                        <use href="#icon-pen"></use>
                      </svg>
                      <!-- 电话字段回复标识 -->
                      <svg
                        class="icon"
                        v-if="
                          replyPhoneShow &&
                          item.schemefieldType === 'H' &&
                          scope.row[item.schemefieldName]
                        "
                        aria-hidden="true"
                        @click="callPhone(scope.row[item.schemefieldName])"
                        style="cursor: pointer"
                      >
                        <use href="#icon-Call"></use>
                      </svg>
                    </span>
                  </template>
                </cc-table-column>
                <!--  单选下拉框 -->
                <cc-table-column
                  v-else-if="item.options && !item.isInput"
                  :align="item.align ? item.align : 'left'"
                  :label="item.nameLabel"
                  :width="
                    item.colwidth
                      ? item.colwidth
                      : item.schemefieldType === 'ct'
                      ? '175'
                      : ''
                  "
                  :min-width="item.schemefieldType === 'ct' ? '175' : ''"
                  :prop="item.schemefieldName"
                  :fixed="item.fixed"
                  :sortable="item.sortable"
                  :show-overflow-tooltip="columnTooltip"
                >
                  <template #header v-if="customHeader">
                    <div
                      class="tableHeadField"
                      @mouseout="outSvg(3, item)"
                      @mouseover="overSvg(3, item)"
                      @click="changeSortFiled($event, item)"
                    >
                      <el-popover
                        placement="top-start"
                        :open-delay="openDelay"
                        trigger="hover"
                        popper-class="my-tooltip table-head-tooltip"
                        :content="nameLableList[itemIndex]"
                      >
                        <span slot="reference">
                          {{ nameLableList[itemIndex] }}
                        </span>
                      </el-popover>
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        :style="{
                          transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                        }"
                        v-show="sortDir && sortField === item.apiname"
                      >
                        <use href="#icon-a-Ascendingorder"></use>
                      </svg>
                      <!-- 未选中，上浮样式 -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        v-show="
                          isSort(item) &&
                          item.id === hoveHeaID &&
                          !(sortDir && sortField === item.apiname)
                        "
                      >
                        <use href="#icon-a-Nosort"></use>
                      </svg>
                      <!-- 表头右侧svg 锁定列 未锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-if="showLock"
                        v-show="item.id === hoveHeaID && !item.locked"
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-222"></use>
                        </svg>
                      </span>
                      <!-- 锁定列 锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-show="
                          item.id === hoveHeaID && item.locked && showLock
                        "
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-111"></use>
                        </svg>
                      </span>
                    </div>
                  </template>
                  <!-- 等待修改,是否需要添加tooltip -->
                  <template slot-scope="scope">
                    <span
                      v-if="
                        (item.apiname == 'severity' ||
                          item.apiname == 'priority') &&
                        (item.options[scope.row[item.schemefieldName]] ==
                          '高' ||
                          scope.row[item.schemefieldName] == '高')
                      "
                      style="color: red"
                      v-html="showSelectVal(item, scope)"
                    >
                    </span>
                    <span v-else v-html="showSelectVal(item, scope)"> </span>
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </template>
                </cc-table-column>
                <!-- a标签可跳转类型字段 - 查找、查找多选、所有人 -->
                <cc-table-column
                  v-else-if="item.click && !item.isInput && useHyperlinks"
                  :align="item.align ? item.align : 'left'"
                  :label="item.nameLabel"
                  :width="item.colwidth ? item.colwidth : ''"
                  :prop="item.schemefieldName"
                  :fixed="item.fixed"
                  :sortable="item.sortable"
                  :show-overflow-tooltip="columnTooltip"
                >
                  <template #header v-if="customHeader">
                    <div
                      class="tableHeadField"
                      @mouseout="outSvg(3, item)"
                      @mouseover="overSvg(3, item)"
                      @click="changeSortFiled($event, item)"
                    >
                      <el-popover
                        placement="top-start"
                        :open-delay="openDelay"
                        trigger="hover"
                        popper-class="my-tooltip table-head-tooltip"
                        :content="nameLableList[itemIndex]"
                      >
                        <span slot="reference">
                          {{ nameLableList[itemIndex] }}
                        </span>
                      </el-popover>
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        :style="{
                          transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                        }"
                        v-show="sortDir && sortField === item.apiname"
                      >
                        <use href="#icon-a-Ascendingorder"></use>
                      </svg>
                      <!-- 未选中，上浮样式 -->
                      <svg
                        class="icon sortImg"
                        aria-hidden="true"
                        v-show="
                          isSort(item) &&
                          item.id === hoveHeaID &&
                          !(sortDir && sortField === item.apiname)
                        "
                      >
                        <use href="#icon-a-Nosort"></use>
                      </svg>
                      <!-- 表头右侧svg 锁定列 未锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-if="showLock"
                        v-show="item.id === hoveHeaID && !item.locked"
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-222"></use>
                        </svg>
                      </span>
                      <!-- 锁定列 锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-show="
                          item.id === hoveHeaID && item.locked && showLock
                        "
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-111"></use>
                        </svg>
                      </span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <!-- 名称 主题-->
                    <!-- 非文件相关列表需要跳转，反之下载 -->
                    <a
                      class="cursor-pointer allow-click"
                      v-if="
                        (objId &&
                          objId !== 'attachement' &&
                          item.schemefieldName === 'name') ||
                        item.schemefieldName === 'subject'
                      "
                      :target="targetType"
                      :href="scope.row.nameHrefUrl"
                      @click.right.stop="handleUrl(scope.row)"
                      @click.ctrl.exact="ctrlClick(item, scope, item.click, true)"
                      @click.meta.exact="ctrlClick(item, scope, item.click, true)"
                      @click.exact="jumpTo(item, scope, item.click, true)"
                    >
                      <span class="cannotEdit">
                        {{ scope.row[`${item.schemefieldName}`] }}
                      </span>
                      <svg
                        class="icon editIconImg"
                        aria-hidden="true"
                        style="display: none"
                        @click="editCell(item, scope.row)"
                      >
                        <use href="#icon-pen"></use>
                      </svg>
                    </a>
                    <span
                      class="cursor-pointer allow-click"
                      v-else-if="
                        objId &&
                        objId === 'attachement' &&
                        item.schemefieldName === 'name'
                      "
                      @click="downLoadFile(scope.row)"
                    >
                      <span class="cannotEdit">
                        {{ scope.row[`${item.schemefieldName}`] }}
                      </span>
                    </span>
                    <!-- 所有人 - 非队列 : 可跳转 -->
                    <span
                      v-else-if="
                        item.schemefieldType === 'Y' &&
                        item.lookupObj === 'user' &&
                        scope.row[`${item.schemefieldName}`] &&
                        scope.row[`${item.schemefieldName}`].slice(0, 3) ===
                          '005'
                      "
                      class="user_box"
                    >
                      <headPortrait
                        :headHeight="headHeight"
                        :headWidth="headWidth"
                        :objname="scope.row[`${item.schemefieldName}ccname`]"
                      />
                      <a
                        class="cursor-pointer allow-click"
                        :target="targetType"
                        :href="scope.row.nameHrefUrl"
                        @click.right.stop="handlehrefUrl(scope.row, item)"
                        @click.exact="jumpTo(item, scope, item.click)"
                        @click.ctrl.exact="ctrlClick(item, scope, item.click)"
                        @click.meta.exact="ctrlClick(item, scope, item.click)"
                      >
                        {{ scope.row[`${item.schemefieldName}ccname`] }}
                        <svg
                          class="icon editIconImg"
                          aria-hidden="true"
                          style="display: none"
                          @click="editCell(item, scope.row)"
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                      </a>
                    </span>
                    <!-- 所有人 - 队列 : 不可跳转 -->
                    <span
                      v-else-if="
                        item.schemefieldType === 'Y' &&
                        scope.row[`${item.schemefieldName}`] &&
                        scope.row[`${item.schemefieldName}`].slice(0, 3) ===
                          'que'
                      "
                    >
                      {{ scope.row[`${item.schemefieldName}ccname`] }}
                      <svg
                        class="icon editIconImg"
                        aria-hidden="true"
                        style="display: none"
                        @click="editCell(item, scope.row)"
                      >
                        <use href="#icon-pen"></use>
                      </svg>
                    </span>

                    <!-- 查找字段 公海池字段不支持跳转 -->
                    <a
                      class="cursor-pointer allow-click"
                      v-else-if="
                        item.schemefieldType !== 'MR' &&
                        item.apiname !== 'marketsea'
                      "
                      :target="targetType"
                      :href="scope.row.nameHrefUrl"
                      @click.right.stop="handlehrefUrl(scope.row, item)"
                      @click.exact="jumpTo(item, scope, item.click)"
                      @click.ctrl.exact="ctrlClick(item, scope, item.click)"
                      @click.meta.exact="ctrlClick(item, scope, item.click)"
                    >
                      <!-- 名称和相关项 -->
                      <span
                        class="cannotEdit"
                        v-if="
                          item.schemefieldName === 'relateid' ||
                          item.schemefieldName === 'whoid'
                        "
                      >
                        {{
                          item.schemefieldName === "relateid" ||
                          item.schemefieldName === "whoid"
                            ? scope.row[`${item.schemefieldName}ccname`]
                            : scope.row[`${item.schemefieldName}`]
                        }}
                      </span>
                      <span v-else class="cannotEdit">
                        {{
                          item.schemefieldType === "Y" ||
                          item.schemefieldType === "MR" ||
                          item.schemefieldType === "M"
                            ? scope.row[`${item.schemefieldName}ccname`]
                            : scope.row[`${item.schemefieldName}`]
                        }}
                        <svg
                          class="icon editIconImg"
                          aria-hidden="true"
                          style="display: none"
                          @click="editCell(item, scope.row)"
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                      </span>
                    </a>
                    <span v-else class="cannotEdit">
                      {{
                        item.schemefieldType === "Y" ||
                        item.schemefieldType === "MR" ||
                        item.schemefieldType === "M" ||
                        item.schemefieldName === "relateid" ||
                        item.schemefieldName === "whoid"
                          ? scope.row[`${item.schemefieldName}ccname`]
                          : scope.row[`${item.schemefieldName}`]
                      }}
                      <svg
                        class="icon editIconImg"
                        aria-hidden="true"
                        style="display: none"
                        @click="editCell(item, scope.row)"
                      >
                        <use href="#icon-pen"></use>
                      </svg>
                    </span>
                  </template>
                </cc-table-column>
                <!-- 不使用a标签 - 查找、查找多选、所有人 -->
                <cc-table-column
                  v-else-if="item.click && !item.isInput && !useHyperlinks"
                  :align="item.align ? item.align : 'left'"
                  :label="item.nameLabel"
                  :width="item.colwidth ? item.colwidth : ''"
                  :prop="item.schemefieldName"
                  :fixed="item.fixed"
                  :sortable="item.sortable"
                  :show-overflow-tooltip="columnTooltip"
                >
                  <template #header v-if="customHeader">
                    <div
                      class="tableHeadField"
                      @mouseout="outSvg(3, item)"
                      @mouseover="overSvg(3, item)"
                      @click="changeSortFiled($event, item)"
                    >
                      <el-popover
                        placement="top-start"
                        :open-delay="openDelay"
                        trigger="hover"
                        popper-class="my-tooltip table-head-tooltip"
                        :content="nameLableList[itemIndex]"
                      >
                        <span slot="reference">
                          {{ nameLableList[itemIndex] }}
                        </span>
                      </el-popover>
                      <!-- 表头右侧svg 锁定列 未锁定svg-->
                      <span
                        class="popover-span"
                        v-if="showLock"
                        @click.stop="locked(item)"
                        v-show="item.id === hoveHeaID && !item.locked"
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-222"></use>
                        </svg>
                      </span>
                      <!-- 锁定列 锁定svg-->
                      <span
                        class="popover-span"
                        @click.stop="locked(item)"
                        v-show="
                          item.id === hoveHeaID && item.locked && showLock
                        "
                      >
                        <svg class="icon sortImg" aria-hidden="true">
                          <use href="#icon-a-111"></use>
                        </svg>
                      </span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <!-- 查找字段 -->
                    <span
                      class="cursor-pointer allow-click"
                      v-if="item.schemefieldType !== 'MR'"
                      @click.exact="jumpTo(item, scope, item.click)"
                      @click.ctrl.exact="ctrlClick(item, scope, item.click)"
                      @click.meta.exact="ctrlClick(item, scope, item.click)"
                    >
                      <!-- 名称和相关项 -->
                      <span
                        class="cannotEdit"
                        v-if="
                          item.schemefieldName === 'relateid' ||
                          item.schemefieldName === 'whoid'
                        "
                      >
                        {{
                          item.schemefieldName === "relateid" ||
                          item.schemefieldName === "whoid"
                            ? scope.row[`${item.schemefieldName}ccname`]
                            : scope.row[`${item.schemefieldName}`]
                        }}
                      </span>
                      <span v-else class="cannotEdit">
                        {{
                          item.schemefieldType === "Y" ||
                          item.schemefieldType === "MR" ||
                          item.schemefieldType === "M"
                            ? scope.row[`${item.schemefieldName}ccname`]
                            : scope.row[`${item.schemefieldName}`]
                        }}
                        <svg
                          class="icon editIconImg"
                          aria-hidden="true"
                          style="display: none"
                          @click="editCell(item, scope.row)"
                        >
                          <use href="#icon-pen"></use>
                        </svg>
                      </span>
                    </span>
                    <span v-else class="cannotEdit">
                      {{
                        item.schemefieldType === "Y" ||
                        item.schemefieldType === "MR" ||
                        item.schemefieldType === "M" ||
                        item.schemefieldName === "relateid" ||
                        item.schemefieldName === "whoid"
                          ? scope.row[`${item.schemefieldName}ccname`]
                          : scope.row[`${item.schemefieldName}`]
                      }}
                      <svg
                        class="icon editIconImg"
                        aria-hidden="true"
                        style="display: none"
                        @click="editCell(item, scope.row)"
                      >
                        <use href="#icon-pen"></use>
                      </svg>
                    </span>
                  </template>
                </cc-table-column>
              </slot>
            </template>

            <slot></slot>
          </cc-table>
        </div>
      </YiScrollbar>
      <!-- 表格内联编辑浮窗 -->
      <div class="lineBox" v-show="dialogVisible" ref="lineBox">
        <div class="editBox">
          <editable-cell
            ref="editableCell"
            :field="field"
            :schemefieldLength="schemefieldLength"
            :options="editableCellOptions"
            :value="toChangeValue"
            :isFilter="false"
            :input-type="inputType"
            :err-tip="errTip"
            :precision="precision"
            :min="min"
            :max="max"
            origin-type="table"
            width="calc(100% - 52px)"
            @remoteSearch="remoteSearch"
          >
          </editable-cell>
          <div>
            <!-- 保存 -->
            <svg
              class="icon lineBoxEdit"
              aria-hidden="true"
              @click="saveChange"
            >
              <use href="#icon-Hook"></use>
            </svg>
            <!-- 取消 -->
            <svg
              class="icon lineBoxEdit"
              aria-hidden="true"
              @click="handleClose"
            >
              <use href="#icon-fork"></use>
            </svg>
          </div>
        </div>
        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others && checkedList.length > 1"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
      </div>
      <!--  -->

      <!-- 表格内联编辑弹窗 -->
      <div class="dialogModel">
        <el-dialog
          top="0"
          width="0%"
          :modal="false"
          :show-close="false"
          :visible.sync="dialogVisible"
          :before-close="handleClose"
        >
        </el-dialog>
      </div>
      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="beforeClose"
        append-to-body
      >
        <div
          :style="{
            'min-height': dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <search-table
            ref="searchTable"
            :dialogBodyHeight="dialogBodyHeight"
            :fieldId="relevantFieldId"
            :checked="multiChecked"
            :relevant-objid="relevantObjId"
            :relevant-objapi="relevantObjApi"
            :relevant-prefix="relevantPrefix"
            :projectId="projectId"
            :optionCheckedArr="optionCheckedArr"
            @changeSelect="changeSelect"
            @setFieldReltaion="setFieldReltaion"
          />
        </div>
      </el-dialog>
    </div>
    <!-- 分页、统计 -->
    <div class="flexBox">
      <!-- 统计 -->
      <div class="statisticsBox">
        <span v-if="isQueryCount">
          <!-- 共{{total}}条数据 -->
          {{ $t("vue_label_commonobjects_view_sum", { total: total }) }}
        </span>
        <!-- 统计字段 -->
        <span v-if="typeAry.length > 0 && typeAry.length < 4">
          <span
            class="spanBox"
            v-for="(obj, index) in typeAry"
            :key="obj.label"
          >
            <span v-if="index === 0">
              <i class="iiBox"> | </i>
              <!-- 当前页统计: -->
              {{ $t("c185") }}
            </span>
            <svg width="8" height="8">
              <circle cx="4" cy="4" r="2" stroke="black" />
            </svg>

            <span class="apinameBox">{{ obj.label }}</span>
            <span class="iBox">:</span> {{ obj.value }}</span
          >
        </span>
        <span v-if="typeAry.length > 0 && typeAry.length > 3">
          <span
            class="spanBox"
            v-for="(obj, index) in typeAry.slice(0, 3)"
            :key="obj.label"
          >
            <span v-if="index === 0">
              <i class="iiBox"> | </i>
              <!-- 当前页统计: -->
              {{ $t("c185") }}
            </span>
            <svg width="8" height="8">
              <circle cx="4" cy="4" r="2" stroke="black" />
            </svg>

            <span class="apinameBox">{{ obj.label }}</span>
            <span class="iBox">:</span> {{ obj.value }}</span
          >
          <!-- 更多图标 -->
          <span @mouseout="outSvg(4)" @mouseover="overSvg(4)">
            <el-dropdown placement="top">
              <span class="svgBox4">
                <svg class="icon admin_ss" aria-hidden="true">
                  <use :href="svgIcon2"></use>
                </svg>
              </span>
              <!-- 更多内容展示 -->
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in typeAry.slice(3)"
                  :key="item.label"
                >
                  {{ item.label }} <span class="iBox">:</span> {{ item.value }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </span>
      </div>
      <!-- 分页 -->
      <div class="paginationBox">
        <el-pagination
          background
          small
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 25, 50, 100, 200]"
          :page-size="pageSizeList"
          :pager-count="5"
          layout=" prev, pager, next, sizes,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 服务报告预览 -->
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
    <!-- 管理列功能弹窗 -->
    <el-dialog
      custom-class="handleCol"
      :title="$t('c127')"
      :visible.sync="handleCol"
      :show-close="false"
      append-to-body
      destroy-on-close
      top="2%"
      width="30%"
    >
      <drag-table
        ref="dragTable"
        :unselectedFieldList="unselectedFieldList"
        :selectedFieldList="selectedFieldList"
        destroy-on-close
        @memberFun="memberFun"
      ></drag-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCol = false" size="small">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button type="primary" @click="saveSelectedField" size="small">
          <!-- 保存 -->
          {{ $t("label.save") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- 业务机会/报价单/订单/发票/采购订单（列表页内联编辑）切换价格手册提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="pricIntro"
      append-to-body
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 更改已选取的价格手册，将删除全部现有的产品。是否更改为新的价格手册？ -->
        {{ $t("c731") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelPricChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmPricChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <rejection 
      v-if="utOrderRejectFlag"
      ref="rejectionRef"
      :utOrderRejectFlag="utOrderRejectFlag"
      :serviceappointmentId="serviceappointmentId"
      :serviceterritoryId="serviceterritoryId"
      @orderReject="orderReject"
      @cancelOrderRejectFlag="cancelOrderRejectFlag"
    ></rejection>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * 新版列表表格组件
 * 功能：
 * 1、动态渲染表格
 * 2、管理列，重置列宽，编辑展示列，列排序
 * 3、表格行内编辑
 * 4、分页、统计
 * 5、数据选中
 * 6、骨架屏
 * 7、跳转服务报告预览
 */
import { consoleSaveTab } from "@/views/home/api.js";
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import { sortFidld } from "@/utils/field/sortField.js";
import EditableCell from "./EditableCell.vue";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api.js";
import filePreview from "@/components/FileView/filePreview";
import createButton from "@/utils/Button";
import headPortrait from "@/views/gantt/resourceManage/components/headPortrait";
import debounce from "lodash.debounce";
import dragTable from "./sort.vue";
import YiScrollbar from "./myScroll.vue";
import { mapState } from "vuex";
export default {
  components: {
    dragTable,
    EditableCell,
    filePreview,
    headPortrait,
    YiScrollbar,
    rejection: () => import("./rejection.vue"),
  },
  props: {
    // 表头编辑权限数据
    headerEditInfo:{
      type: Array,
      default:() => []
    },
    // 是否开启内联编辑
    enableInlineEdit: {
      type: Boolean,
      default: true,
    },
    // 编辑记录id，表格数据单条更新使用
    editId: {
      type: String,
      default: "",
    },
    // 是否开启表格单条数据更新
    editFlag: Boolean,
    // 是否展示数据总条数
    isQueryCount: {
      type: Boolean,
      default: false,
    },
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    // 页码
    pageNum: {
      type: Number,
      defalt: 1,
    },
    // 当前页数
    currentPage: {
      type: Number,
      defalt: 1,
    },
    //每页多少条数据
    pageSizeList: {
      type: Number,
      defalt: 50,
    },
    // 总数据数
    total: {
      type: Number,
      defalt: 0,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 每一列数据的 id
    id: {
      type: String,
      default: "id",
    },
    // 是否条纹状表格
    stripe: {
      type: Boolean,
      default: false,
    },
    // 当前视图是否可编辑
    viewModify: {
      type: String,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 表格数据
    pageObj: {
      type: Object,
      default: () => {},
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    // 是否展示编辑表格字段
    showTableHeadTool: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: null,
    },
    // 按钮
    tableButton: {
      // 按钮
      type: [Array, Object],
      default: () => [],
    },
    // 选择框
    checked: {
      type: Boolean,
      default: false,
    },
    // 表格高度,如果是字符串，必须是px为单位
    // tableHeight: {
    //   type: [String, Number, undefined],
    //   default: undefined,
    // },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },
    // 已选中列
    selectedList: {
      type: Array,
      default: () => [],
    },
    // 合并列算法
    spanMethod: {
      type: [Function, undefined],
      default: undefined,
    },
    // 表头行的 className 的回调方法，也可以使用字符串为所有表头行设置一个固定的 className
    headerRowClassName: {
      type: [Function, String],
      default: undefined,
    },
    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 对象id，判断对象是否有编辑、删除权限
    objId: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 重置列宽时使用
    ismodifyFalg: {
      type: String,
      default: "",
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    // 是否显示锁定功能
    showLock: {
      type: Boolean,
      default: true,
    },
    // 相关列表的对象信息(这里判断了pageObj为undefined,不能修改默认值)
    itemObj: {
      type: Object,
      default: () => {},
    },
    // 排序字段
    sortField: {
      type: String,
      default: "",
    },
    // 排序方式
    sortDir: {
      type: String,
      default: "",
    },
    //是否展示筛选器
    showScreen: {
      type: Boolean,
    },
    // 打开详情页方式
    targetType: {
      type: String,
      default: "_self",
    },
  },
  data() {
    return {
      // 表格列信息，用于记录列宽，拖动列，只改变当前列的宽度，其他不变
      columns: {},
      // 拖动列默认最小宽度
      minWidth: 100,
      // 查找、主详、查找多选字段，查找弹窗组件使用，选中
      multiChecked: false,
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantFieldId: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantObjId: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantObjApi: "",
      // 查找、主详、查找多选字段，查找弹窗组件使用
      relevantPrefix: "",
      // 查找多选
      optionCheckedArr: [],
      // 清空当前rowid定时器
      timeOutClear: -1,
      // 定时器变量
      timeOut: -1,
      // 统计的字段类型数据
      typeAry: [],
      // 鼠标上浮表格头id
      hoveHeaID: NaN,
      // svg管理列图标
      svgIcon: "#icon-Normal-Management",
      // svg编辑列图标
      svgIcon1: "#icon-Normal-more1",
      // svg统计图标
      svgIcon2: "#icon-Normal-more1",
      // 管理列弹窗
      handleCol: false,
      // 表头悬浮提示延迟时间
      openDelay: 500,
      // 查找多选弹窗
      showSearchTable: false,
      // 查找多选弹窗宽度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      // 国家code，表格回显日期使用
      countryCode: this.$cookies.get("countryCode"),
      // 骨架屏数据
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      // 评分字段最大分值
      schemefieldLength: 0,
      // 表格展示的数据
      dataList: [],
      // 表格选中的数据（需要判断下和editableCellOptions是否可以共用一个）
      checkedList: [],
      // 表格单选下拉框
      columnTooltip: true,
      // 表头的name数组
      nameLableList: [],
      // 选中表头
      selectedFieldList: [],
      // 未来选中表头
      unselectedFieldList: [],
      // 内联编辑提示
      errTip: this.$i18n.t("label.vlidaterule.message"),
      // 内联编辑要修改的字段名称
      field: "",
      // 内联编辑要修改的字段ApiName
      fieldApiName: "",
      // 内联编辑是否应用于全部选中数据
      changeAll: false,
      // 内联编辑时，控制是否可以全部应用于选中数据展示
      showApply2Others: false,
      // 内联编辑所有选中的数据集合（应该能和表格选中数据集合用同一个变量）
      editableCellOptions: [],
      // 内联编辑要修改数据显示类型
      inputType: "",
      // 内联编辑要修改的数据
      toChangeValue: null,
      // 内联编辑弹窗
      dialogVisible: false,
      //   pin: false, // 是否按住shift键（表格shift多选功能后期要加上）
      //单元格编辑字段信息
      editableCellAttr: null,
      // 编辑单元格时的rowId
      dataId: "",
      // 内联编辑时当前记录的数据，用于查找筛选条件匹配
      rowData: {},
      // 潜在客户打分颜色
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      // 编辑单元格数字时的小数位
      precision: 0,
      // 编辑单元格数字时的最小值
      min: 0,
      // 编辑单元格数字时的最大值
      max: 0,
      inlineedit: false, // 全局内联编辑权限
      // 管理员权限
      objModifyAll: null,
      // 对象编辑权限
      objModify: null,
      // 对象删除权限
      objDelete: null,
      // token
      token: this.$CCDK.CCToken.getToken(),
      // 管理列时选中的表头id数据
      str: "",
      // 管理列图标
      DropChangeBoll: false,
      // 项目id
      projectId: "",
      // 文件预览参数
      showPreview: false,
      // 服务报告预览数据
      showPreviewData: {},
      // 服务报告预览url
      previewUrl: "",
      //保存加载状态
      isSaveBtnLoading: false,
      //tbody局部 loading
      jubuloading: null,
      // 所有人宽
      headWidth: "23px",
      // 所有人高
      headHeight: "23px",
      // 列表页内联编辑是否切换价格手册弹框
      pricIntro: false,
      // 价格手册行数据
      priceRow: [],
      // 修改价格手册是否触发确定按钮
      priceIsDelSon: false,
      // 记录id
      recordId: "",
      // 不能强制修改props值
      tableButtonData: this.tableButton,
      // 是否跳转到PDF视图详情页
      isPdfViewStatus: "",
      // 是否展示打电话图标
      replyPhoneShow: false,
      // 拒单弹框
      utOrderRejectFlag:false,
      // 服务预约id
      serviceappointmentId:'',
      // 服务区域id
      serviceterritoryId:'',
    };
  },
  computed: {
    ...mapState(["consoleData"]),
  },
  created() {
    // 潜在客户热度设置信息
    this.getLeadHeat();
    // 更新全局内联编辑权限
    this.inlineedit = localStorage.getItem("inlineedit");
  },
  mounted() {
    window.addEventListener("keyup", this.keyupEvent);
    // 获取对象权限
    this.getObjectPermission(this.objId);
    // 获取是否跳转到PDF视图详情页
    this.getTemplateViewStatus();
    // 修改分页跳转提示文字
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = this.$i18n.t("label.tabpage.skip");
    this.replyPhoneShowClick();
  },
  methods: {
    // 判断是否有编辑权限
    hasEditPermission(headerLabel, row, col){
      return headerLabel.find(item => item.apiname === col.property)?.modify === 'true'
    },
    //  电话回复
    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
    // 电话回复图标判断
    replyPhoneShowClick() {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf?.tooltype) {
        this.replyPhoneShow = true;
      } else {
        this.replyPhoneShow = false;
      }
    },
    /**
     * keyupEvent:监听键盘回车
     * @param {Object} code:事件对象
     */
    keyupEvent(code = {}) {
      // 内联编辑回车保存
      if (code.keyCode === 13 && this.dialogVisible) {
        this.saveChange();
      }
    },
    /*
     * 报价单，订单，发票，采购订单，跳转详情页之前判断是打开详情页还是PDF视图详情页
     */
    getTemplateViewStatus() {
      let params = {
        obj: this.objectApi, //对象id
      };
      this.$store.commit("setObjectApi", this.objectApi);
      this.$store.commit("setObjId", this.objId);
      this.$store.commit("setPrefix", this.prefix);
      // 查询详情页视图类型接口
      CommonObjApi.getTemplateViewStatus(params).then((res) => {
        this.isPdfViewStatus = res.data;
        // 保存跳转详情页类型
        this.$store.commit("setIsPdfViewStatus", res.data);
      });
    },
    /**
     * formatProgress:进度条格式化显示格式
     * 使用show-text会有样式问题
     */
    formatProgress() {
      return "";
    },
    /**
     * 设置el-table__body-wrapper的高度，使其能展示出横向滚动条
     */
    setTableHeight() {
      let tableHeadHeight =
        document.querySelector(".myTable .el-table__header-wrapper")
          .offsetHeight || 36;
      //
      let tableHeight = this.$refs.tableBox.offsetHeight - tableHeadHeight;

      document.querySelector(".myTable .el-table__body-wrapper").style.height =
        tableHeight + "px";

      document.querySelector(
        ".myTable .el-table__fixed-body-wrapper"
      ).style.height = tableHeight - 10 + "px";
    },
    haveScroll() {
      let tableBodyHeight = document.querySelector(
        ".myTable .el-table__body-wrapper"
      );
      //
      //
      let barItem = document.querySelector(".scrollBarItem");
      barItem.style.width =
        (tableBodyHeight.clientWidth / tableBodyHeight.scrollWidth) * 100 + "%";
      return tableBodyHeight.scrollWidth > tableBodyHeight.clientWidth;
    },
    /**
     * rowClassName：设置行样式
     * @param {object} { row, rowIndex } 当前行数据
     * @returns {string} 样式名或者""
     */
    rowClassName({ row }) {
      let checkIdList = this.checkedList.map((item) => item.id);
      if (checkIdList.includes(row.id)) {
        return "rowBox_chen";
      }
      return "";
    },
    /**
     * DropChange：管理列打开下拉，打开列菜单，改变图标展示
     * @param {number} val 下拉菜单对应的值
     * @returns {null}
     */
    DropChange(val) {
      this.DropChangeBoll = val;
      if (!val) {
        this.svgIcon = "#icon-Normal-Management";
      }
    },
    /**
     * handlestatisticsList：处理统计展示数据
     * @param {}
     * @returns {}
     */
    handlestatisticsList() {
      if (this.typeAry.length > 0) {
        this.typeAry.forEach((item) => {
          item.value = 0;
        });
        // 有展示的统计项
        this.dataList.forEach((item) => {
          let keyAry = Object.keys(item);
          this.typeAry.forEach((obj) => {
            if (keyAry.includes(obj.apiname)) {
              switch (obj.type) {
                case "N":
                  // 、数字 1,234,567.000 格式
                  if (item[obj.apiname]) {
                    let numberStr = item[obj.apiname].toString();
                    let val1 = numberStr.split(",").join("");
                    //  记录小数点位数
                    let num1 = val1.split(".")[1]
                      ? val1.split(".")[1].length
                      : 0;
                    obj.places = num1;
                    obj.value += Number(val1);
                  }
                  break;
                default:
                  // 字符串 CNY 2,324,434.00 或者AUD 10.00 (CNY 53.22)格式
                  if (item[obj.apiname] && item[obj.apiname] !== "0") {
                    let reg = /\(.+\)/g;
                    let apiname = item[obj.apiname].toString();
                    if (apiname.match(reg) && apiname.match(reg)[0]) {
                      let apistr = apiname.match(reg)[0];
                      apiname = apistr.substring(1, apistr.length - 1);
                    }
                    let ary1 = apiname.split(",");
                    let ary2 = ary1[0].split(" ");
                    obj.prefix = ary2[0];
                    ary1.shift();
                    let val = ary2[1] + ary1.join("");
                    //  记录小数点位数
                    let num = val.split(".")[1] ? val.split(".")[1].length : 0;
                    obj.places = num;
                    obj.value += Number(val);
                  }
              }
            }
          });
        });
      }
      // 格式化展示的统计数据
      this.typeAry.forEach((item) => {
        if (item.value) {
          let value = item.value.toFixed(item.places);
          let ary = value.split(".");
          let ary2 = ary[0].split("").reverse();
          let str = "";
          ary2.forEach((item, index) => {
            if (index !== 0 && index % 3 === 0) {
              str += "," + item;
            } else {
              str += item;
            }
          });
          // 需要判断是否有小数位
          item.value =
            str.split("").reverse().join("") + (ary[1] ? "." + ary[1] : "");
          if (item.type === "c") {
            // 币种
            item.value = item.prefix + " " + item.value;
          }
        }
      });
    },
    /**
     * handleSizeChange：分页pageSize 每页条数改变
     * @param {number} val 改变后的每页展示的数据条数
     * @returns {}
     */
    handleSizeChange(val) {
      // 滚动条回到顶部
      this.$refs.tableBox.scrollTop = 0;
      this.$emit("update:pageSizeList", val);
      this.$emit("getViewListData", "noClearn");
    },
    /**
     * handleCurrentChange：currentPage 当前页改变时会触发
     * @param {number} val 改变后当前页数
     * @returns {}
     */
    handleCurrentChange(val) {
      // 滚动条回到顶部
      this.$refs.tableBox.scrollTop = 0;
      this.$emit("update:currentPage", val);
      this.$emit("getViewListData", "noClearn");
    },
    /**
     * cancelAllchecked：取消表格的全部全选，方便组件外部取消表格选中操作使用
     * @param {}
     * @returns {}
     */
    cancelAllchecked() {
      this.$refs.tablePanel.clearSelection();
      this.checkedList = [];
    },
    /**
     * resetFun：重置列宽
     * @param {}
     * @returns {}
     */
    resetFun() {
      let data = {};
      if (this.ismodifyFalg === "true") {
        data = {
          viewId: this.viewId,
        };
      } else {
        data = {
          viewId: this.prefix + "_" + this.viewId,
        };
      }
      CommonObjApi.ResettingViewFieldMemory(data).then(() => {
        // 刷新表头
        this.$parent.getViewListHeader();
      });
    },
    /**
     * handleCommand：点击管理列下拉菜单
     * @param {number} val  管理列对应的数值
     * @returns {}
     */
    handleCommand(val) {
      switch (val) {
        case 1:
          // 管理列
          this.searchViewDetail();
          break;
        case 2:
          // 重置列宽
          this.resetFun();
      }
    },
    /**
     * outSvg：离开svg方法，用于切换svg展示图标
     * @param {number,object} val： 管理列对应的数值 item: 当前对象
     * @returns {}
     */
    outSvg(val) {
      switch (val) {
        case 1:
          if (!this.DropChangeBoll) {
            this.svgIcon = "#icon-Normal-Management";
          }
          break;
        case 2:
          this.svgIcon1 = "#icon-Normal-more1";
          break;
        case 3:
          // 鼠标离开表格头
          this.hoveHeaID = NaN;
          break;
        case 4:
          // 鼠标离开表格头
          this.svgIcon2 = "#icon-Normal-more1";
          break;
      }
    },
    // 上浮svg
    overSvg(val, item) {
      switch (val) {
        case 1:
          this.svgIcon = "#icon-a-Click-Management2";
          break;
        case 2:
          this.svgIcon1 = "#icon-Click-more";
          break;
        case 3:
          this.hoveHeaID = item.id;
          // 鼠标上浮表格头
          break;
        case 4:
          // 鼠标离开表格头
          this.svgIcon2 = "#icon-Click-more";
          break;
      }
    },
    //添加操作项
    handleclick(row) {
      if (row.template) {
        if (row.template == "false") {
          this.tableButtonData.buttonList.splice(
            3,
            1,
            createButton({
              // 标记为模板
              label: this.$i18n.t("front-project-module-v1-market-is-template"),
              action: "marktemplate",
            })
          );
        } else {
          this.tableButtonData.buttonList.splice(
            3,
            1,
            createButton({
              // 取消标记模板
              label: this.$i18n.t(
                "front-project-module-v1-unmarket-is-template"
              ),
              action: "unmarktemplate",
            })
          );
        }
      } else {
        this.tableButtonData.buttonList.splice(3, 1);
      }
      // 服务预约对象下点击操作按钮时判断是否显示接单拒单
      if(this.objId == 'serviceappointment'){
        this.oRButton(row.id);
      }
    },
    //打开局部loading 父组件外部调用
    openFullScreen() {
      if (!this.jubuloading) {
        this.jubuloading = this.$loading({
          lock: true,
          text: this.$i18n.t("label.file.loading"),
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255,0.9)",
          target: document.getElementsByClassName("el-table__body-wrapper")[1],
        });
      }
    },
    checkboxSelect(row) {
      if (
        row.their_task &&
        window.location.href.substring(window.location.href.length - 3) == "p03"
      ) {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },

    //关闭局部loading 父组件外部调用
    closeFullScreen() {
      this.$nextTick(() => {
        this.jubuloading.close();
        this.jubuloading = null;
      });
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 获取对象权限
    getObjectPermission(objId) {
      if (objId !== null && objId !== "") {
        if (this.itemObj === undefined) {
          CommonObjApi.getObjectPermission({ id: objId }).then((res) => {
            this.objModifyAll = res.data.modifyAll || false;
            this.objModify = res.data.modify || false;
            this.objDelete = res.data.delete || false;
          });
        } else {
          this.objModifyAll = false;
          this.objModify = this.itemObj.services.modify;
          this.objDelete = this.itemObj.services.delete;
        }
      } else {
        this.objModifyAll = false;
        this.objModify = false;
        this.objDelete = false;
      }
    },
    // 强制刷新
    changeTableAttr(tableAttr) {
      this.nameLableList = [];
      tableAttr &&
        tableAttr.forEach((item) => {
          this.nameLableList.push(item.nameLabel);
        });
      this.$nextTick(() => {
        this.$refs.tablePanel && this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    // 查找/查找多选
    remoteSearch(val) {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
        let checkoutList = [...this.editableCellOptions].filter((x) =>
          [...val].some((y) => x.value === y)
        ); // 取并集
        let option = [];
        checkoutList.forEach((res) => {
          option.push({
            id: res.value,
            name: res.label,
          });
        });
        this.optionCheckedArr = option;
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      this.priceRow = row;
      let options = [];
      let optionValue = [];
      this.optionCheckedArr = [];
      this.showSearchTable = false;

      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
        this.optionCheckedArr = row;
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue.push(row.data.id);
        optionValue = optionValue.toString();
        this.optionCheckedArr.push(row.data);
      }
      if (
        row.data &&
        row.field.name == "价格手册名称" &&
        (this.objectApi === "cloudccorder" ||
          this.objectApi === "quote" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccpurchaseorder" ||
          this.objectApi === "Opportunity")
      ) {
        /**
         * 订单、报价单、发票、采购订单、业务机会等对象（列表页内联编辑）修改价格手册时，需查看相关子产品是否有数据，
         * 有则给出提示语，否则直接修改价格手册
         * @param {String} objId 对象id
         * @param {String} recordId 相关记录id
         */
        let params = {
          objId: this.objId,
          recordId: this.recordId,
        };
        CommonObjApi.existRelatedProductInfo(params).then((res) => {
          if (res.data == "true") {
            this.pricIntro = true;
          } else {
            this.editableCellOptions = options;
            this.toChangeValue = { value: optionValue };
          }
        });
      } else {
        this.editableCellOptions = options;
        this.toChangeValue = { value: optionValue };
      }
    },
    // 取消（内联编辑是否切换价格手册弹框）
    // 取消修改价格手册，价格手册赋回老值
    cancelPricChange() {
      this.pricIntro = false;
    },
    // 确定修改价格手册（内联编辑是否切换价格手册弹框）
    // 确定修改价格手册，价格手册赋新值，并且保存时调用删除子产品数据的接口（deleteRelatedProductInfo）
    confirmPricChange() {
      this.pricIntro = false;
      let options = [];
      let optionValue = [];
      this.optionCheckedArr = [];
      this.showSearchTable = false;
      if (this.priceRow instanceof Array) {
        this.priceRow.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
        this.optionCheckedArr = this.priceRow;
      } else {
        options.push({
          label: this.priceRow.data.name,
          value: this.priceRow.data.id,
        });
        optionValue.push(this.priceRow.data.id);
        optionValue = optionValue.toString();
        this.optionCheckedArr.push(this.priceRow.data);
      }
      this.editableCellOptions = options;
      this.toChangeValue = { value: optionValue };
      this.priceIsDelSon = true; //修改价格手册弹框触发了确定按钮
    },
    /**
     * 内联编辑查找字段时，设置查找筛选条件。
     * 注意：筛选条件如果设置field才需要前端传值，设置的筛选条件字段必须在表格列中出现才有效
     * @param {Array} filterFieldIds:设置筛选条件的数组，包含条件对应的字段id和字段类型
     */
    setFieldReltaion(filterFieldIds) {
      let values = [""];
      if (Array.isArray(filterFieldIds) && filterFieldIds.lenght > 0) {
        values.push(this.dataId);
        let attrMap = new Map();
        // 表头数据,生成字段id和apiname的map
        this.tableAttr.forEach((item) => {
          attrMap.set(item.id, item.schemefieldName);
        });
        // 找到对应筛选条件的值
        filterFieldIds.forEach((item) => {
          // 根据字段id获的该字段的props
          let fieldProps = attrMap.get(item.fieldid);
          if (fieldProps) {
            // 该字段的被设置了筛选条件，将值放入筛选条件查询参数中
            let val = this.rowData[fieldProps] || "";
            values.push(val);
          } else {
            // 即使没有值也要存空字符串，必须保证顺序
            values.push("");
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 下拉框类型字段显示值处理
    showSelectVal(item, scope) {
      let showContnet = "";
      let option =
        item.options &&
        item.options.find((option) => {
          return option.codekey === scope.row[item.schemefieldName];
        });
      if (option !== undefined) {
        showContnet = option.codevalue;
      } else {
        showContnet = scope.row[item.schemefieldName] || "";
      }
      return showContnet;
    },
    // 保存视图选中字段
    saveSelectedField() {
      this.$refs.dragTable.curGroupLists();
      this.selectedFieldList = this.$refs.dragTable.allselectedList;
      this.unselectedFieldList = this.$refs.dragTable.allunselectedList;
      this.$nextTick(() => {
        this.handleCol = false;
        this.$emit("saveSelectedField", this.str);
      });
    },
    // 节流函数
    jieliu(fn, delay) {
      if (this.timeOut !== -1) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        fn();
        this.timeOut = -1;
      }, delay);
    },
    // 内联编辑时做的事
    enterFun(row, column, cell) {
      this.recordId = row.id; // 记录id
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 列表页'币种'字段编辑权限处理
      if (attr[0] && attr[0].apiname === "currency") {
        // 订单、报价单、发票、采购订单、业务机会（列表页）币种'子记录'不为空时不可编辑
        if (
          this.objectApi === "quote" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccorder" ||
          this.objectApi === "cloudccpurchaseorder" ||
          this.objectApi === "Opportunity"
        ) {
          /**
           * 订单、报价单、发票、采购订单、业务机会（列表页内联编辑）修改币种时，
           * 需查看相关子产品是否有数据，有则不可内联编辑，无则显示编辑按钮
           * @param {String} objId 对象id
           * @param {String} recordId 相关记录id
           */
          let params = {
            objId: this.objId,
            recordId: this.recordId,
          };
          CommonObjApi.existRelatedProductInfo(params).then((res) => {
            if (res.data == "true") {
              cell.getElementsByClassName("editIconImg")[0]
                ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                    "none")
                : "";
            } else {
              cell.getElementsByClassName("editIconImg")[0]
                ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                    "")
                : "";
            }
          });
        }
        // 除（订单、报价单、发票、采购订单、业务机会）其他对象币种字段支持内联编辑
        // 这里特殊判断了'产品'对象，产品任何情况下都不支持编辑
        else if (
          (this.objectApi != "quote" ||
            this.objectApi != "Invoices" ||
            this.objectApi != "cloudccorder" ||
            this.objectApi != "cloudccpurchaseorder" ||
            this.objectApi != "Opportunity") &&
          this.objectApi != "product"
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
            : "";
        }
        // '产品'对象，不支持编辑
        else if (this.objectApi == "product") {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        }
      }
      // 客户(个人客户) -- 客户名称"name"、联系人(个人客户生成的联系人) -- 联系人名称"name"不可内联编辑
      if (attr[0] && attr[0].apiname === "name") {
        if (
          (this.objectApi === "Account" || this.objectApi === "Contact") &&
          row.ispersonaccount === "true"
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        } else if (
          this.objectApi !== "Account" ||
          this.objectApi !== "Contact" ||
          (this.objectApi === "Contact" && row.ispersonaccount !== "true") ||
          (this.objectApi === "Account" && row.ispersonaccount !== "true")
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
            : "";
        }
      }
      // 联系人(个人客户生成的联系人) -- 客户名称"khmc"不可内联编辑
      if (attr[0] && attr[0].apiname === "khmc") {
        if (this.objectApi === "Contact" && row.ispersonaccount === "true") {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        } else if (
          this.objectApi !== "Contact" ||
          (this.objectApi === "Contact" && row.ispersonaccount !== "true")
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
            : "";
        }
      }
      // 图片、文件字段、币种（币种是否可内联编辑不走改判断）不可内联编辑
      if (
        this.inlineedit &&
        attr[0] &&
        attr[0].schemefieldType !== "IMG" &&
        attr[0].schemefieldType !== "FL" &&
        attr[0].apiname !== "currency" &&
        attr[0].apiname !== "name" &&
        attr[0].apiname !== "khmc"
      ) {
        // 通过对象编辑权限和字段编辑权限判断是否展示
        attr[0] &&
        // attr[0].modify === "true" 
        this.hasEditPermission(this.headerEditInfo, row, column)
        &&
        (this.objModifyAll || this.objModify) &&
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
          : "";
          
      }

      // 实际人工成本、预计人工成本，项目任务/问题-总持续时长不展示列表行内编辑
      if (
        attr[0] &&
        (this.objectApi === "cloudcc_real_labor_costs" ||
          this.objectApi === "cloudccPlanCost" ||
          ((this.objectApi === "cloudccTask" ||
            this.objectApi === "CloudccProblem") &&
            attr[0].apiname === "continue_date") ||
          attr[0].apiname === "taskOrProblem" ||
          (this.objectApi === "CloudccProjectActualWorkList" &&
            attr[0].apiname === "project_name") ||
          attr[0].apiname === "their_project_name" ||
          attr[0].apiname === "project_name" ||
          attr[0].apiname === "their_project" ||
          (this.objectApi == "cloudccSubtask" &&
            attr[0].apiname === "their_milestone") ||
          attr[0].apiname === "principal" ||
          attr[0].apiname === "their_task" ||
          (this.objectApi === "cloudccSubtask" &&
            attr[0].apiname === "continue_date"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell, event) {
      // 判断是否开启内联编辑
      if (!this.enableInlineEdit) return;
      // 清空上次修改rowid的定时器
      clearTimeout(this.timeOutClear);
      this.jieliu(() => {
        this.enterFun(row, column, cell, event);
      }, 200);
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell) {
      // 判断是否开启内联编辑
      if (!this.enableInlineEdit) return;
      // 清空当前rowid
      if (this.timeOutClear !== -1) {
        clearTimeout(this.timeOutClear);
      }
      this.timeOutClear = setTimeout(() => {
        //  防止最后一个小笔不消失
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }, 500);

      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },
    // 查询视图详细信息
    searchViewDetail() {
      if (
        (this.viewId === "0" ||
          this.viewId === "1" ||
          this.viewId === "2" ||
          this.viewId === "3") &&
        this.viewModify !== "true"
      ) {
        return;
      } else {
        this.getFieldSetup();
      }
    },
    // 获取管理列的表头字段数据
    getFieldSetup() {
      this.selectedFieldList = [];
      this.unselectedFieldList = [];

      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      }).then((res) => {
        this.selectedFieldList = res.data.viewSelectedFieldList;
        this.unselectedFieldList = res.data.viewUnselectedFieldList;
        // 打开管理列弹窗
        this.$nextTick(() => {
          this.handleCol = true;
        });
      });
    },
    /**
     * 判断该字段是否支持排序功能
     * @param {Object} field:排序字段对象
     * @return {Boolean} 是否支持排序
     */
    isSort(field = {}) {
      return !(
        sortFidld.apiname.includes(field.schemefieldName) ||
        sortFidld.type.includes(field.schemefieldType)
      );
    },
    // 修改排序字段
    changeSortFiled: debounce(function (e, field, type) {
      // 判断该字段是否支持排序功能
      if (!this.isSort(field)) return;
      if (type) {
        type == 1 ? (field.sortDesc = false) : (field.sortAsc = false);
      } else {
        if (field.sortDesc) {
          field.sortAsc = true;
          field.sortDesc = false;
        } else {
          field.sortAsc = false;
          field.sortDesc = true;
        }
      }
      field.sortDesc && (this.$parent.sortDir = "desc");
      field.sortAsc && (this.$parent.sortDir = "asc");
      if (!field.sortDesc && !field.sortAsc) {
        this.$parent.sortDir = "";
      }
      if (e?.target?.tagName !== "I") {
        this.$emit("changeSortFiled", field);
      }
    }, 500),
    // 按‘字段名’排序（字段排序需要确定该需求）
    changeFilter(field) {
      if (field.filterScreen) {
        this.$parent.showScreen = true;
        this.$emit("changeFilterFiled", field);
      } else {
        this.$parent.showScreen = false;
      }
      this.changeTableAttr(this.tableAttr);
    },
    // 自动换行
    wordWrap() {
      this.$emit("wordWrap", ...arguments);
    },
    // 锁定
    locked(item, flag = false) {
      // 改变锁定状态
      item.locked = flag ? true : !item.locked;
      this.$emit("locked", ...arguments);
    },
    // 文件下载
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      if (this.objId && this.objId === "attachement") {
        link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${file.id}`;
      } else {
        //视图列表的文件下载
        link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
          file.fileid || file.file_info_id || file.id
        }`;
      }
      link.click();
    },
    // nameHrefUrl右击打开
    handleUrl(row) {
      this.nameHrefUrl(row);
    },
    // 向tab中添加item
    /**
     *
     * @param {*} row
     * @param {*} items
     * @param {*} flag  是否需要跳转
     */
    setTabItem(row, items,flag) {
      //  所有人需要特殊处理（因为所有人没有列表页）
      if (items && items.schemefieldType == "Y" && (items.lookupObj == "user" || items.lookupobj == "user")) {
        this.$CCDK.CCPage.openDetailPage(
          {
            oprateType: "DETAIL",
            objectName: row[`${items.schemefieldName}ccname`],
            objId: this.consoleData.objId,
            objectApi: this.consoleData.objectApi,
            prefix: this.consoleData.prefix,
          },
          row[items.schemefieldName],
          { openPlace: "menu1", openMode: "_blank" }
        );
        return;
      }
      // 生成一级tab数据
      let Level1TabItem = {};
      Level1TabItem.id = items?.schemefieldName
        ? row[items.schemefieldName]
        : row.id;
      Level1TabItem.name = items?.schemefieldName
        ? row[`${items.schemefieldName}ccname`]
        : row.name || row.subject;
      // 设置当前选中tab的样式
      if(!flag){
         this.consoleData.data.level1Id = items?.schemefieldName
        ? row[items.schemefieldName]
        : row.id;
      }

      // 判断是否存在当前点击项
      let res = this.consoleData.data.list.some((item) => {
        //
        if (item.id == Level1TabItem.id) {
          return true;
        } else {
          return false;
        }
      });
      if (!res) {
        // 如果不存在再push
        this.consoleData.data.list.push(Level1TabItem);
      }
      // 当前对象要跳转其他对象详情页时item不为空，获取其他对象的信息
      if (items) {
        CommonObjApi.getPermissionById({ id: row[items.schemefieldName] }).then(
          (ress) => {
            this.consoleData.objId = ress.data.objId;
            this.consoleData.objectApi = ress.data.objectApi;
            this.consoleData.prefix = ress.data.prefix;
            this.consoleData.viewId = "1";
            this.$store.commit("setConsoleData", this.consoleData);
            // 将数据保存到后端
            consoleSaveTab({
              appid: this.$store.state.home.homeApplicationId,
              opentab: JSON.stringify(this.consoleData),
            });
            if(!flag){
               this.$router.push({
                path: "/commonObjects/console-multi-screen",
                query: {
                  objectApi: ress.data.objectApi,
                  objId: ress.data.objId,
                  prefix: ress.data.prefix,
                  viewId: "1",
                  rowId: items?.schemefieldName
                    ? row[items.schemefieldName]
                    : row.id,
                },
              });
            }

          }
        );
      } else {
        this.consoleData.objId = this.objId;
        this.consoleData.objectApi = this.objectApi;
        this.consoleData.prefix = this.prefix;
        this.consoleData.viewId = this.viewId;
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
        if(!flag){
           this.$router.push({
            path: "/commonObjects/console-multi-screen",
            query: {
              objectApi: this.objectApi,
              objId: this.objId,
              prefix: this.prefix,
              viewId: this.viewId,
              rowId: items?.schemefieldName ? row[items.schemefieldName] : row.id,
            },
          });
        }

      }
    },
    // 名称字段跳转路径
    nameHrefUrl(row) {
      // 联系人(个人客户生成的联系人name字段跳转至个人客户详情页)
      if (row.ispersonaccount === "true" && this.objectApi === "Contact") {
        row.nameHrefUrl = row.iskhid
          ? `#/commonObjects/detail/${row.iskhid}/DETAIL`
          : "";
      }
      //如果是服务报告、推送编号则禁止跳转
      // else if (
      //   this.objectApi == "servicereport" ||
      //   this.objectApi == "ServiceReportPushRecord"
      // ) {
      //   row.nameHrefUrl = `javascript:void(0);`;
      // } // 服务控制台跳转新个案详情
      else if (
        this.objectApi === "cloudcccase" &&
        this.$route.query.isConsole === "true"
      ) {
        row.nameHrefUrl = `javascript:void(0);`;
      } else {
        // 在跳转详情页前存储下当前视图id，方便在返回对象列表页时仍然显示对应视图内容
        this.$store.commit("setViewId", this.viewId);
        // 导航样式为控制台样式
        if (this.$store.state.navigationStyle) {
          this.setTabItem(row);
          // row.nameHrefUrl = row.id ? `#/commonObjects/console-multi-screen/${this.objectApi}/${this.objId}/${this.prefix}?sortFieldName=&sortField=&sortDir=&viewId=${this.viewId}&rowId=${row.id}` : "";
        } else {
          // 保存记录的状态,记录的编号
          if (
            this.objectApi == "Invoices" ||
            this.objectApi == "cloudccorder" ||
            this.objectApi == "cloudccpurchaseorder"
          ) {
            this.$store.commit("setObjectNumber", row?.name);
          } else {
            this.$store.commit("setObjectNumber", row?.numbers);
          }
          if (this.objectApi == "quote") {
            this.$store.commit("setRecordStatus", row?.zhuangtai);
          } else {
            this.$store.commit("setRecordStatus", row?.status);
          }
          row.nameHrefUrl = row.id
            ? `#/commonObjects/detail/${row.id}/DETAIL`
            : "";
        }
      }
    },
    // 右击打开
    handlehrefUrl(row, item) {
      this.hrefUrl(row, item);
    },
    // 所有人字段跳转路径
    hrefUrl(row, item) {
      // 项目管理-实际工作清单点击日期跳转详情
      if (
        this.$cookies.get("activeTabObjId") === "projectworklist" &&
        item.schemefieldType === "D"
      ) {
        this.$store.commit("setViewId", this.viewId);
        // 导航样式为控制台样式
        if (this.$store.state.navigationStyle) {
          this.setTabItem(row);
          // row.nameHrefUrl = row[item.schemefieldName] ? `#/commonObjects/console-multi-screen/${this.objectApi}/${this.objId}/${this.prefix}?sortFieldName=&sortField=&sortDir=&viewId=${this.viewId}&rowId=${row.id}` : "";
        } else {
          row.nameHrefUrl = row[item.schemefieldName]
            ? `#/commonObjects/detail/${row.id}/DETAIL`
            : "";
        }
      } else if (item.apiname === "slaprocessid") {
        //如果是权利过程则禁止跳转
        row.nameHrefUrl = `javascript:void(0);`;
      } else if (
        item.apiname === "servicereporttemplateid" ||
        item.apiname === "reportid"
      ) {
        //如果是服务报告模板则禁止跳转
        row.nameHrefUrl = `javascript:void(0);`;
      } else if (row.type === "sellhoper" && item.schemefieldName === "name") {
        // 导航样式为控制台样式
        if (this.$store.state.navigationStyle) {
          // row.nameHrefUrl = `javascript:void(0);`
          this.setTabItem(row);
        } else {
          row.nameHrefUrl = `#/commonObjects/detail/${row.id}/DETAIL`;
        }
      } else {
        this.$store.commit("setViewId", this.viewId);
        // 导航样式为控制台样式
        if (this.$store.state.navigationStyle) {
          // row.nameHrefUrl = `javascript:void(0);`
          this.setTabItem(row, item);
        } else {
          row.nameHrefUrl = row[item.schemefieldName]
            ? `#/commonObjects/detail/${row[item.schemefieldName]}/DETAIL`
            : `javascript:void(0);`;
        }
      }
    },
    // url跳转
    jumpToUrl(url) {
      if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    // 按钮权限判断
    ifButtonShow(rowData, btn) {
      // itemObj有值证明是相关列表的操作列
      if (this.itemObj !== undefined && this.itemObj.services) {
        if (
          btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
          this.itemObj.services.modify
        ) {
          return true;
        } else if (
          btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
          this.itemObj.services.delete
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
          (this.objModifyAll || this.objModify)
        ) {
          return true;
        } else if (
          btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
          (this.objModifyAll || this.objDelete)
        ) {
          return true;
        } else if (
          btn.label !== this.$i18n.t("pagecreator_page_button_edit") &&
          btn.label !== this.$i18n.t("component_setup_tabs_label_delete") &&
          (this.objModifyAll || this.objModify)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 表格拖动列时触发的操作
    headerDragend(newWidth, oldWidth, column) {
      this.columns[column.property] = newWidth;
      // 判断newWidth是否小于最小宽度，如果小于，则值为最小宽度，如果未设置最小宽度，需要对比newWidth是否小于默认的minWidth，如果小于，则宽度为默认的minwidth
      if (column.minWidth && column.minWidth > newWidth) {
        column.width = column.minWidth;
        this.columns[column.property] = column.minWidth;
        // `${column.label}最小宽度为${column.minWidth}px`
        this.$message.warning(
          this.$i18n.t("c955", {
            label: column.label,
            minWidth: column.minWidth,
          })
        );
      } else if (newWidth < this.minWidth) {
        column.width = this.minWidth;
        this.columns[column.property] = this.minWidth;
      }
      // 只改变拖动列的宽度
      this.$refs.tablePanel.columns.forEach((item) => {
        if (!item.width) {
          item.width = this.columns[item.property];
        }
      });
      let fieldId = "";
      this.tableAttr.forEach((attr) => {
        if (attr.schemefieldName === column.property) {
          fieldId = attr.id;
        }
      });
      let params = {
        viewId: this.viewId,
        fieldId: fieldId,
        colwidth: Math.round(newWidth),
      };
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        params.viewId = `${this.prefix}_${this.viewId}`;
      }
      if (this.viewId !== "") {
        CommonObjApi.updateViewFieldMemory(JSON.stringify(params)).then(() => {
          // 更新表格滚动条
          this.$refs.yiScrollbar.initV();
        });
      }
    },
    // 列表中操作
    handleTableAction(data, action) {
      let idx = data.$index + 1;
      let rowData = data.row;
      let actionName = action.action;
      let emitData = {
        index: idx, // 当前点击位置
        data: rowData, // 当前点击数据
        action: actionName, // 执行当前动作名称
        type: action.type || undefined, // 当前操作类别
        val: action.val || undefined, // 点击当前按钮派发的自定义值
      };
      // 若无管理员权限，判断按钮权限
      if (data.row.attachtype) {
        this.$emit(actionName, emitData);
      } else {
        CommonObjApi.getPermissionById({ id: data.row.id }).then((res) => {
          if (action.action === "del") {
            // 非锁定状态且有编辑权限时可删除
            if (res.data.isDelete && !res.data.isLocked) {
              this.$emit(actionName, emitData);
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_notice_nopermission_delete"),
                type: "warning",
              });
              //抱歉，您无权删除该条数据，请联系系统管理员。
            }
          } else if (action.action === "edit") {
            if (res.data.isEdit) {
              // 联系人列表"编辑"(个人客户生成的联系人)
              if (
                data.row.ispersonaccount &&
                data.row.ispersonaccount === "true" &&
                this.objectApi === "Contact"
              ) {
                // id为当前个人客户的id
                emitData.data.id = emitData.data.iskhid;
              }
              this.$emit(actionName, emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权编辑该条数据，请联系系统管理员。"
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (
            action.type !== "orderReceiving" &&
            action.type !== "rejection" &&
            action.action !== "edit" &&
            action.action !== "del" &&
            action.action !== "marktemplate" &&
            action.action !== "unmarktemplate"
          ) {
            if (res.data.canEditOwner) {
              // 联系人列表"更改所有人"(个人客户生成的联系人)
              if (
                data.row.ispersonaccount &&
                data.row.ispersonaccount === "true" &&
                this.objectApi === "Contact"
              ) {
                // id为当前个人客户的id
                emitData.data.id = emitData.data.iskhid;
                actionName = "transferClientOwner";
              }
              this.$emit(actionName, emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权更改该条数据所有人，请联系系统管理员。""
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (
            action.action == "marktemplate" ||
            action.action == "unmarktemplate"
          ) {
            if (res.data.isEdit) {
              this.$emit("marktemplate", emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权编辑该条数据，请联系系统管理员。"
                this.$i18n.t("report_label_norole")
              );
            }
          }else if(action.type=='orderReceiving'){
            // 接单
            this.serviceappointmentId=data.row.id;
            this.getOrderReceiving();
          }else if(action.type=='rejection'){
            // 拒单：获取拒单原因，显示拒单弹框，拒单原因默认选择计划冲突
            this.utOrderRejectFlag=true;
            this.serviceappointmentId=data.row.id;
          }
        });
      }
    },
    // 关闭拒单原因弹框,拒单成功刷新当前数据，否则只关闭弹框
    cancelOrderRejectFlag(value){
      this.utOrderRejectFlag=false;
      if(value=='success'){
        this.orderReject('success')
      }else{
        this.orderReject('del')
      }
    },
    // 拒单成功隐藏按钮
    orderReject(value){
      // 接单成功后隐藏接单拒单按钮
      this.tableButtonData.buttonList.map((item,index)=>{
        if(item.type=='orderReceiving'){
          this.tableButtonData.buttonList.splice(index,1)
        }
      })
      this.tableButtonData.buttonList.map((item,index)=>{
        if(item.type=='rejection'){
          this.tableButtonData.buttonList.splice(index,1)
        }
      })
      if(!value){
        // 单条记录刷新
        this.$emit('save',this.serviceappointmentId)
      }else if(value == 'success'){
        // 拒单成功后刷新列表，该记录拒单后会不显示在列表
        this.$emit('save')
      }
    },
    // 服务预约列表页显示接单和拒单按钮
    async oRButton(id){
      let params={
        serviceappointmentId:id
      }
      let res = await CommonObjApi.oRButton(params);
      if(res.result){
        // status为0:不显示；1:显示接单拒单按钮；
        // 当存在拒单按钮时，调取拒单原因接口
        if(res.data.status=='1'){
          this.tableButtonData.buttonList.splice(
            3,
            1,
            createButton({
              label:this.$i18n.t("c2321"),
              type:'orderReceiving',
            })
          )
          this.tableButtonData.buttonList.splice(
            4,
            1,
            createButton({
              label:this.$i18n.t("c2322"),
              type:'rejection',
            })
          )
          this.serviceterritoryId=res.data.serviceterritoryId;
        }else{
          this.orderReject('del')
        }
      }
    },
    // 接单接口
    async getOrderReceiving(){
      let params={
        serviceappointmentId:this.serviceappointmentId,//服务预约id
      }
      let res = await CommonObjApi.utOrderReceive(params);
      if(res.result&&res.returnCode!='2'){
        this.orderReject()
        this.$message.success(this.$i18n.t("c2175")) 
      }else{
        this.$message.error(this.$i18n.t("c2176"))
      }
    },
    //操作项 标记为模板切换 父组件外部调用
    checkboxmarktemplate(val) {
      if (val == "unmarktemplate") {
        this.tableButtonData.buttonList.splice(
          3,
          1,
          createButton({
            // 标记为模板
            label: this.$i18n.t("front-project-module-v1-market-is-template"),
            action: "marktemplate",
          })
        );
      } else {
        this.tableButtonData.buttonList.splice(
          3,
          1,
          createButton({
            // 取消标记模板
            label: this.$i18n.t("front-project-module-v1-unmarket-is-template"),
            action: "unmarktemplate",
          })
        );
      }
    },
    // 点击单元格触发，获取所在单元格位置
    changLine(row, column, cell) {
      let cellObj = cell.getBoundingClientRect();
      let lineBoxLeft =
        window.innerWidth - cellObj.left > 240
          ? cellObj.left
          : window.innerWidth - 240;
      let lineBoxTop =
        window.innerHeight - cellObj.top > 64
          ? cellObj.top
          : window.innerHeight - 64;
      this.$refs.lineBox.style.top = lineBoxTop + "px";
      this.$refs.lineBox.style.left = lineBoxLeft + "px";
      // 弹窗默认宽度240px，如果当前单元格宽度大于240px，则展示当前单元格的宽度
      if (cellObj.width > 240) {
        this.$refs.lineBox.style.width = cellObj.width + "px";
      } else {
        this.$refs.lineBox.style.width = "240px";
      }
    },
    // 编辑单元格
    editCell(item, row) {
      //项目管理行内编辑根据项目对里程碑、任务、成员做限制
      this.projectId = row.project_name || row.their_project;
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();

      // 根据Id获取数据权限
      CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
        if (res.data.isEdit) {
          // 点击单元格内编辑图标时不跳转
          this.dataId = row.id;
          this.rowData = Object.assign({}, row);
          this.changeAll = false;
          this.editableCellOptions = [];
          this.inputType = INPUTTYPE[item.schemefieldType];
          this.showApply2Others =
            item.schemefieldType == "X" || item.schemefieldType == "MR"
              ? false
              : true;
          // 列表点击日期编辑处理
          if (item.type === "D") {
            this.toChangeValue = row[item.schemefieldName]
              ? { value: new Date(row[item.schemefieldName]) }
              : "";
          } else {
            this.toChangeValue = { value: row[item.schemefieldName] };
          }
          this.editableCellAttr = item;
          // 复选框类型无返回值时
          if (item.schemefieldType === "B") {
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined
                  ? false
                  : row[item.schemefieldName] === "true"
                  ? "true"
                  : "false",
            };
          } else if (
            item.schemefieldType === "X" &&
            item.apiname === "cloudcctag"
          ) {
            // 标签
            this.inputType = "tag";
            this.toChangeValue = { value: row["cloudcctagList"] };
          }
          // 查找、主详、查找多选字段
          else if (
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.relevantFieldId = item.id;
            this.relevantObjId = item.lookupObj;
            this.multiChecked = item.schemefieldType === "MR" ? true : false;
            this.editableCellOptions = [];
            if (
              row[item.schemefieldName] &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType !== "MR"
            ) {
              this.editableCellOptions = [
                {
                  label: row[`${item.schemefieldName}ccname`],
                  value: row[item.schemefieldName],
                },
              ];
            } else if (
              row[item.schemefieldName] &&
              row[item.schemefieldName] !== "" &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType === "MR"
            ) {
              let values = row[item.schemefieldName].split(";");
              let labels = row[`${item.schemefieldName}ccname`].split(";");
              values.forEach((item, idx) => {
                this.editableCellOptions.push({
                  label: labels[idx],
                  value: values[idx],
                });
              });
              this.toChangeValue = { value: values };
            }
          } else if (
            item.schemefieldType === "L" ||
            item.schemefieldType === "Q" ||
            item.schemefieldType === "ct"
          ) {
            if(item.schemefieldType === "L" ||
            item.schemefieldType === "Q" ){
              // 选项列表通过接口拿选项数据
              this.getSelectValue(row,item)
            }else{
              // 选择/多选/币种类型
              this.editableCellOptions = [];
              item.options.forEach((ele) => {
                this.editableCellOptions.push({
                  label: ele.codevalue,
                  value: ele.codekey,
                });
              });
            }
            // 选择/多选/币种类型
            this.editableCellOptions = [];
              item.options.forEach((ele) => {
                this.editableCellOptions.push({
                  label: ele.codevalue,
                  value: ele.codekey,
                });
              });
            
          } else if (this.inputType === "number") {
            this.precision = item.decimalPlaces
              ? Number(item.decimalPlaces)
              : 0;
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 币种字段可能带币种类型，特殊处理
            if (item.schemefieldType === "c") {
              let changeValue = 0;
              if (
                row[item.schemefieldName] !== undefined &&
                row[item.schemefieldName] !== null
              ) {
                changeValue =
                  row[item.schemefieldName].indexOf("(") !== -1
                    ? row[item.schemefieldName].split("(")[0]
                    : row[item.schemefieldName];
                changeValue =
                  changeValue.indexOf(" ") !== -1
                    ? changeValue.trim().split(" ")
                    : // [changeValue.trim().split(" ").length - 1].replace(
                      //   /,/g,
                      //   ""
                      // )
                      changeValue;
              }
              this.toChangeValue = {
                value:
                  changeValue[1] === undefined || changeValue[1] === null
                    ? changeValue[1]
                    : changeValue[1].replace(/,/g, ""),
              };
            }
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "percent-number") {
            this.precision = Number(item.decimalPlaces);
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "score") {
            this.schemefieldLength = Number(item.schemefieldLength);
            this.toChangeValue = { value: Number(row[item.schemefieldName]) };
          }
          this.field = item.nameLabel;
          this.fieldApiName = item.apiname;
          this.dialogVisible = true;
          // this.$refs.dialogBox.style.z-index = 2;
          this.$refs.editableCell && this.$refs.editableCell.setInputValue();
        } else {
          this.$message.warning(
            // "抱歉，您无权编辑该条数据，请联系系统管理员。"
            this.$i18n.t("report_label_norole")
          );
        }
      });
    },
    /**
     * 内联编辑选项列表获取下拉项
     * @param {*} row :内联编辑当前数据信息
     * @param {*} column :内联编辑当前字段列信息
     */
     getSelectValue(row,column){
      if(row.recordtype || row.id){
        let targetAttr = this.tableAttr.find(item => (item.schemefieldName === column.property ||item.schemefieldName === column.apiname || item.schemefieldName === column.schemefieldName))
        if(targetAttr && targetAttr.id){
          let param = {
            fieldId:targetAttr.id,
            recordTypeId:row.recordtype,
            recordId:row.id
          }
          CommonObjApi.getSelectValue(param).then(res => {
            if(res.data && Array.isArray(res.data.selectedValues)){
              this.editableCellOptions = [];
              res.data.selectedValues.forEach(item => {
                // todo:这里没有根据多语言改变
                this.editableCellOptions.push({
                    label: item.codevalue,
                    value: item.keyval,
                  });
              })
            }
          })
        }
       
        
      }
      
     },
    // 表格被双击
    async cellDoubleClick(row, column) {
      // 判断是否开启内联编辑
      if (!this.enableInlineEdit) return;
      // 附件不需要进行权限校验
      if (
        this.$route.params.type === "file" ||
        (this.itemObj && this.itemObj.relatedlistType === "attachement")
      ) {
        return;
      }
      // 查找要显示的数据类型
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 事件及任务下的名称及相关项去除双击编辑
      if (
        attr[0].apiname === "relateobj" ||
        attr[0].apiname === "relateid" ||
        attr[0].apiname === "whoobj" ||
        attr[0].apiname === "whoid" ||
        (attr[0].apiname === "currency" && this.objectApi === "product") ||
        this.objectApi === "quote" ||
        this.objectApi === "Invoices" ||
        this.objectApi === "cloudccorder" ||
        this.objectApi === "cloudccpurchaseorder" ||
        this.objectApi === "Opportunity"
      ) {
        return false;
      }
      // 实际人工成本、预计人工成本，项目任务/问题-总是持续时长不能进行双击编辑
      if (
        attr[0] &&
        (this.objectApi === "cloudcc_real_labor_costs" ||
          this.objectApi === "cloudccPlanCost" ||
          ((this.objectApi === "cloudccTask" ||
            this.objectApi === "CloudccProblem") &&
            attr[0].apiname === "continue_date") ||
          attr[0].apiname === "taskOrProblem" ||
          (this.objectApi === "CloudccProjectActualWorkList" &&
            attr[0].apiname === "project_name") ||
          attr[0].apiname === "their_project_name" ||
          attr[0].apiname === "project_name" ||
          attr[0].apiname === "their_project" ||
          (this.objectApi == "cloudccSubtask" &&
            attr[0].apiname === "their_milestone") ||
          attr[0].apiname === "principal" ||
          attr[0].apiname === "their_task" ||
          (this.objectApi === "cloudccSubtask" &&
            attr[0].apiname === "continue_date"))
      ) {
        return false;
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        return false;
      }
      // 判断对象编辑权限、字段编辑权限、记录编辑权限
      if (
        attr[0] &&
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        this.inlineedit
      ) {
        let res = await CommonObjApi.getPermissionById({ id: row.id });
        if (!res.data.isEdit) {
          this.$message.warning(this.$i18n.t("report_label_norole"));
          return;
        }

        this.dataId = row.id;
        this.changeAll = false;
        this.editableCellOptions = [];
        this.showApply2Others = true;

        this.editableCellAttr = attr[0];
        // 图片、文件字段不可内联编辑
        if (
          attr[0].schemefieldType !== "IMG" &&
          attr[0].schemefieldType !== "FL"
        ) {
          if (attr[0].modify === "true") {
            this.inputType = INPUTTYPE[attr[0].schemefieldType];
            this.toChangeValue = { value: row[column.property] };
            // 复选框类型无返回值时
            if (
              attr[0].schemefieldType === "B" &&
              row[column.property] === undefined
            ) {
              this.toChangeValue = {
                value:
                  row[column.property] === undefined
                    ? false
                    : row[column.property] === "true",
              };
            }
            // 标签
            else if (
              attr[0].schemefieldType === "X" &&
              attr[0].apiname === "cloudcctag"
            ) {
              this.inputType = "tag";
              this.toChangeValue = { value: row["cloudcctagList"] };
            }
            // 查找、主详、查找多选字段
            else if (
              attr[0].schemefieldType === "Y" ||
              attr[0].schemefieldType === "M" ||
              attr[0].schemefieldType === "MR"
            ) {
              this.relevantFieldId = attr[0].id;
              this.relevantObjId = attr[0].lookupObj;
              this.multiChecked =
                attr[0].schemefieldType === "MR" ? true : false;
              this.editableCellOptions = [];
              if (
                row[column.property] &&
                row[`${column.property}ccname`] &&
                attr[0].schemefieldType !== "MR"
              ) {
                this.editableCellOptions = [
                  {
                    label: row[`${column.property}ccname`],
                    value: row[column.property],
                  },
                ];
              } else if (
                row[column.property] &&
                row[column.property] !== "" &&
                row[`${column.property}ccname`] &&
                attr[0].schemefieldType === "MR"
              ) {
                let values = row[column.property].split(";");
                let labels = row[`${column.property}ccname`].split(";");
                values.forEach((item, idx) => {
                  this.editableCellOptions.push({
                    label: labels[idx],
                    value: values[idx],
                  });
                });
                this.toChangeValue = { value: values };
              }
            }
            // 选项列表、选项列表多选
            else if (
              attr[0].schemefieldType === "L" ||
              attr[0].schemefieldType === "Q" ||
              attr[0].schemefieldType === "ct"
            ) {
              if(attr[0].schemefieldType === "L" ||
              attr[0].schemefieldType === "Q"){
                // 选项列表通过接口拿选项数据
                this.getSelectValue(row,column)
              }else{
                this.editableCellOptions = [];
                attr[0].options.forEach((item) => {
                  this.editableCellOptions.push({
                    label: item.codevalue,
                    value: item.codekey,
                  });
                });
              }
             
            } else if (this.inputType === "number") {
              this.precision = Number(attr[0].decimalPlaces);
              this.toChangeValue = {
                value:
                  row[column.property] === undefined ||
                  row[column.property] === null
                    ? row[column.property]
                    : row[column.property].replace(/,/g, ""),
              };

              // 币种字段可能带币种类型，特殊处理
              if (attr[0].schemefieldType === "c") {
                let changeValue = 0;
                if (
                  row[column.property] !== undefined &&
                  row[column.property] !== null
                ) {
                  changeValue =
                    row[column.property].indexOf("(") !== -1
                      ? row[column.property].split("(")[0]
                      : row[column.property];
                  changeValue =
                    changeValue.indexOf(" ") !== -1
                      ? changeValue.trim().split(" ")
                      : // [
                        //   changeValue.trim().split(" ").length - 1
                        // ].replace(/,/g, "")
                        changeValue;
                }
                this.toChangeValue = {
                  value:
                    changeValue[1] === undefined || changeValue[1] === null
                      ? changeValue[1]
                      : changeValue[1].replace(/,/g, ""),
                };
              }
              // 控制内联编辑为null时不显示0
              this.toChangeValue.value =
                this.toChangeValue.value === null
                  ? undefined
                  : this.toChangeValue.value;

              this.min =
                0 -
                Math.pow(
                  10,
                  attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                ) +
                1;
              this.max =
                Math.pow(
                  10,
                  attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                ) - 1;
            }

            /**
             * 代码错误，不知道干什么的，test和master分支上都是错误的，就先注释掉了
             */
            // else if (this.inputType === "percent-number") {
            //   this.precision = Number(item.decimalPlaces);
            //   this.toChangeValue = {
            //     value:
            //       row[column.property] === undefined ||
            //       row[column.property] === null
            //         ? row[column.property]
            //         : row[column.property].replace(/,/g, ""),
            //   };
            //   // 控制内联编辑为null时不显示0
            //   this.toChangeValue.value =
            //     this.toChangeValue.value === null
            //       ? undefined
            //       : this.toChangeValue.value;

            //   this.min =
            //     0 -
            //     Math.pow(
            //       10,
            //       Number(item.schemefieldLength) -
            //         Number(item.decimalPlaces) -
            //         1
            //     ) +
            //     1;
            //   this.max =
            //     Math.pow(
            //       10,
            //       Number(item.schemefieldLength) -
            //         Number(item.decimalPlaces) -
            //         1
            //     ) - 1;
            // } else if (this.inputType === "score") {
            //   this.schemefieldLength = Number(item.schemefieldLength);
            //   this.toChangeValue = {
            //     value: Number(row[item.schemefieldName]),
            //   };
            // }
            this.field = attr[0].nameLabel;
            this.fieldApiName = attr[0].apiname;
            this.dialogVisible = true;
            this.$refs.editableCell && this.$refs.editableCell.setInputValue();
          }
        }
      }
    },
    // 内联编辑保存修改字段
    saveChange() {
      let fields = [];
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell?.editValue;
      if (
        !value &&
        (this.editableCellAttr.apiname == "name" ||
          this.editableCellAttr.schemefieldType == "M")
      ) {
        if (this.editableCellAttr.apiname == "name") {
          // name字段必填

          this.$message.warning(this.$i18n.t("c963"));
        }
        if (this.editableCellAttr.schemefieldType == "M") {
          // 主详字段必填
          this.$message.warning(this.$i18n.t("c964"));
        }
        return;
      }
      let precision = this.$refs.editableCell.precision;
      let inputType = this.$refs.editableCell.inputType;
      //数字类型保存传值（小数位不够时补0）
      if (inputType === "number" && value) {
        value = value.toFixed(precision);
      }
      //列表行内编辑-项目管理系统合同金额
      if (this.editableCellAttr.apiname === "contract_amount") {
        let _value = value + "";
        if (_value.length > 10) {
          // 合同金额最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.contract.amount.is.10.digits"
            )
          );
          return;
        }
      }
      //列表行内编辑-项目管理系统 任务工时小时数
      if (this.editableCellAttr.apiname === "daily_working_hours") {
        let _value1 = value;
        if (_value1 > 24 || _value1 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      //列表行内编辑-实际工作清单的实际工作时长
      if (this.editableCellAttr.apiname === "working_hours_billing") {
        let _value2 = value;
        if (_value2 > 24 || _value2 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      this.tableAttr.forEach((item) => {
        // 字段apiname取apiname或者schemefieldName不定
        fields.push(item.apiname || item.schemefieldName);
      });

      if (this.checkedList.length > 1 && this.changeAll) {
        this.checkedList.forEach((item) => {
          let data = { id: item.id };
          data[
            this.editableCellAttr.apiname ||
              this.editableCellAttr.schemefieldName
          ] =
            value === null || value === undefined ? value : this.format(value);
          datas.push(data);
        });
      } else {
        let data = { id: this.dataId };
        data[
          this.editableCellAttr.apiname || this.editableCellAttr.schemefieldName
        ] = value === null || value === undefined ? value : this.format(value);
        datas.push(data);
      }
      // 如果在保存加载状态，直接返回，防止多次保存
      if (this.isSaveBtnLoading) return;
      let regEmail =
        /^([a-z0-9A-Z]*[-_]*[.]?)*@([a-z0-9A-Z]+(-[a-z0-9A-Z-]+)?\.)+[a-z0-9A-Z]{2,}$/;
      if (datas.length > 200) {
        // 最多应用于200条数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else if (
        this.fieldApiName === "twitter" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("twitter.com") === -1
      ) {
        // twitter、LinkedIn、Facebook字段加验证，必须带twitter.com、linkedin.com、facebook.com
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.twittererror")
        );
      } else if (
        this.fieldApiName === "linkedin" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("linkedin.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.linkedinerror")
        );
      } else if (
        this.fieldApiName === "facebook" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("facebook.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.facebookerror")
        );
      } else if (
        this.fieldApiName === "email" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !regEmail.test(value)
      ) {
        this.$message.warning(
          this.$i18n.t("front-kefu-moudle-v1-yxgsbzq-reinput")
        );
      } else {
        //编辑项目任务列表
        if (
          window.location.href.substring(window.location.href.length - 3) ==
          "p03"
        ) {
          // 按钮加载中
          this.isSaveBtnLoading = true;
          CommonObjApi.getPermissionById({ id: datas[0].id })
            .then((res) => {
              let params = {
                objectApi: res.data.objectApi,
                jsonstr: JSON.stringify(datas),
                fields: fields.toString(),
              };
              CommonObjApi.updateViewListAjax(params)
                .then((res) => {
                  this.isSaveBtnLoading = false;
                  res.data.updateResultList.forEach((updateResult) => {
                    if (updateResult.errorMessage) {
                      this.$message.error(updateResult.errorMessage);
                      return;
                    } else {
                      this.$message.success(
                        this.$i18n.t(
                          "label.setup.mobile.bottombutton.change.success"
                        )
                      ); //"修改成功"
                      this.$emit("refresh");
                      this.checkedList = [];
                      this.dialogVisible = false;
                    }
                  });
                })
                .catch(() => {
                  this.isSaveBtnLoading = false;
                });
            })
            .catch(() => {
              this.isSaveBtnLoading = false;
            });
        } else {
          // 更新主记录的价格手册，删除原主记录子产品
          if (this.priceIsDelSon == true) {
            let paramsY = {
              objId: this.objId,
              recordId: this.recordId,
            };
            CommonObjApi.deleteRelatedProductInfo(paramsY).then(() => {});
          }
          let params = {
            objectApi: this.objectApi,
            jsonstr: JSON.stringify(datas),
            fields: fields.toString(),
          };
          //输入值为空时不可保存
          this.isSaveBtnLoading = true;

          CommonObjApi.updateViewListAjax(params)
            .then((res) => {
              this.isSaveBtnLoading = false;
              const data = this.$refs.tablePanel.tableData;
              if (
                res.data !== null &&
                res.data.list &&
                res.data.list.length > 0
              ) {
                res.data.list.forEach((changeItem) => {
                  data.forEach((item, idx) => {
                    if (item.id === changeItem.id && item.name !== "") {
                      // 改变修改项，注意，原项中自定义的属性需要保留
                      changeItem = Object.assign(data[idx], changeItem);
                      // 增加跳转地址属性
                      this.$set(
                        this.$refs.tablePanel.tableData,
                        idx,
                        changeItem
                      );
                    }
                  });
                });
              }
              // 处理提示语
              let returnInfo = "";
              res.data.updateResultList.forEach((updateResult) => {
                if (updateResult.isSuccess !== "true") {
                  returnInfo += this.$i18n.t(
                    "vue_label_notice_batchedit_fail",
                    [updateResult.id, updateResult.errorMessage]
                  );
                }
              });
              if (returnInfo === "") {
                //群策卡片
                //
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
                // 清空选中数据
                this.checkedList = [];
                this.dialogVisible = false;
                this.$refs.tablePanel.clearSelection();
              } else {
                this.$message.error(returnInfo);
              }
            })
            .catch(() => {
              this.isSaveBtnLoading = false;
            });
        }
      }
    },
    //格式转化
    format(val) {
      if (val.constructor == Array) {
        return val.join(";");
      } else {
        return val.toString();
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
    // 打开预览
    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: "pdf",
        type: "pdf",
        id: item,
      };
      let arr = [];
      this.$refs.previewFile.handleBtn(arr);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
    /**
     * 控制台应用程序ctrl+左键，模拟新标签页打开
     */
     ctrlClick(item, scope, cb, flag){
      // 控制台应用程序使用普通左键
      if (this.$store.state.navigationStyle) {
        if (flag) {
        this.setTabItem(scope.row,null,true);
        } else {
          this.setTabItem(scope.row, item,true);
        }
      }else {
        // 非控制台应用程序使用普通左键
        this.jumpTo(item, scope, cb, flag)
      }

     },
    // 跳转事件
    jumpTo(item, scope, cb, flag = false) {
      // 新版控制台情况下阻止默认行为（解决火狐之心javaScript：void（0）跳转问题）
      if (this.$store.state.navigationStyle) {
        event.preventDefault()
      }
      // 更新href
      if (flag) {
        this.handleUrl(scope.row);
      } else {
        this.handlehrefUrl(scope.row, item);
      }
      //如果当前对象API为服务报告则执行此方法
      // if (
      //   this.objectApi == "servicereport" ||
      //   this.objectApi == "ServiceReportPushRecord"
      // ) {
      //   if (scope.row.fileid) {
      //     this.openPreview(scope.row.fileid);
      //   } else {
      //     this.$message.warning(this.$i18n.t("label.file.publicview.error"));
      //   }
      //   return;
      // }
      // 服务控制台跳转新个案详情
      if (
        this.objectApi === "cloudcccase" &&
        this.$route.query.isConsole === "true" &&
        item.apiname === "name"
      ) {
        this.$store.commit("setViewId", this.viewId);
        this.$router.push({
          path: "/controlPanel/control",
          query: {
            caseId: scope.row.id,
            caseOrder: scope.row.name,
            viewId: this.viewId,
          },
        });
        return;
      }
      // 特殊判断，是否存储当前视图id，方便在返回对象列表页时仍然显示对应视图内容
      if (flag) {
        this.$store.commit("setViewId", this.viewId);
      }
      // 判断服务云下的权利下的权利过程根据简档权限跳转lightning设置
      if (item.apiname === "slaprocessid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              isGoDetail: true,
              goDetailId: scope.row.slaprocessid,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
        return;
      }
      // 判断服务云下的工作订单、工作订单行项目、工作类型详情下的服务报告模板根据简档权限跳转lightning设置
      if (item.apiname === "servicereporttemplateid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          let templateObj = scope.row;
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              type: "serviceReportTemplate",
              id: templateObj.servicereporttemplateid,
              label: templateObj.servicereporttemplateidccname,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
        return;
      }
      if (event.target.tagName !== "IMG") {
        let idx = scope.$index + 1;
        let rowData = scope.row;
        let emitData = {
          field: item,
          index: idx, // 当前点击位置
          data: rowData, // 当前点击数据
        };
        this.$emit(cb, emitData);
      }
      // cb && cb(scope)
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      let rowId = row.id;
      let isIncludes = false;
      // 判断当前行是否选中
      this.checkedList.forEach((item, index) => {
        if (item.id === rowId) {
          isIncludes = true;
          this.checkedList.splice(index, 1);
        }
      });
      // 未选中，添加到选中数组中
      if (!isIncludes) {
        this.checkedList.push(row);
      }
    },
    // 行单击事件
    rowClick(row, column) {
      this.$emit("rowClick", row, column);
    },
    // 全选事件
    // TODO:数据的全选可以只记录id，不用记录整个item
    selectAll(selection) {
      let dataIdList = this.dataList.map((item) => item.id);
      let checkIdList = this.checkedList.map((item) => item.id);
      if (selection.length > 0) {
        // selection.length不为0,当前是全选操作
        this.dataList.forEach((item) => {
          if (!checkIdList.includes(item.id)) {
            // 如果之前未选中，则添加进去
            this.checkedList.push(item);
          }
        });
      } else {
        // selection.length为0,当前是取消全选操作
        let aryCopy = [];
        this.checkedList.forEach((item) => {
          if (!dataIdList.includes(item.id)) {
            // 该项选中
            aryCopy.push(item);
          }
        });
        this.checkedList = aryCopy;
      }
      // 通知父组件选中数组改变
      this.$emit("onchange", this.checkedList);
    },

    // 表格选中状态
    selectionChange() {
      // 最好深克隆下
      let list = JSON.parse(JSON.stringify(this.checkedList));
      this.$emit("onchange", list);
      this.$nextTick(()=>{
        // 调用ccdk，保存选中数据的id
      let ids = this.checkedList.map(item=>item.id)
      this.$CCDK.CCObject.setObjectList({ids})
      })

    },
    // 表头管理列保存使用
    memberFun(val) {
      this.str = val;
    },
  },
  destroyed() {
    //   清空所有定时器
    clearTimeout(this.timeOutClear);
    // 清除keyup监听
    window.removeEventListener("keyup", this.keyupEvent);
  },
  watch: {
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (newVal, oldVal) {
        // 数据更新
        // 判断是否需要单条数据更新
        if (this.editId && this.editFlag) {
          // 开启单条数据更新
          let editItem = this.pageObj.dataList.find(
            (item) => item.id === this.editId
          );
          if (editItem) {
            let editIndex = this.dataList.find(
              (item) => item.id === this.editId
            );
            if (editIndex) {
              editItem.index = editIndex.index;
              this.$set(editItem, "isEnter", false);
              this.$set(this.dataList, editIndex.index - 1, editItem);
            }
          }
          // 关闭单条数据更新标识
          this.$emit("update:editFlag", false);
        } else {
          this.dataList = JSON.parse(JSON.stringify(this.pageObj.dataList));
          this.dataList &&
            this.dataList.forEach((item, index) => {
              // 客户列表下的个人客户、联系人下个人客户生成的联系人
              if (item.ispersonaccount === "true") {
                if (item.name) {
                  item.name = item.name.split("ispersonaccount").join("");
                }
                if (item.khmcccname) {
                  item.khmcccname = item.khmcccname
                    .split("ispersonaccount")
                    .join("");
                }
              }
              item.index = index + 1;
              // 此属性标识鼠标移入移出该行状态
              this.$set(item, "isEnter", false);
            });
        }

        // 打印传递数据
        this.$nextTick(() => {
          this.handlestatisticsList();
          //解决第一次触发emit无效问题
          this.$store.state.printList = this.dataList;
          // 回显选中的数据
          if (this.checkedList.length > 0) {
            let idList = this.checkedList.map((item) => item.id);
            this.dataList.forEach((item) => {
              if (idList.includes(item.id)) {
                // 将其设置选中
                this.$refs.tablePanel &&
                  this.$refs.tablePanel.toggleRowSelection(item);
              }
            });
          }
        });

        // 创建选中
        if (!oldVal || !oldVal.dataList) {
          return false;
        }
      },
      deep: true,
    },
    objId(nVal) {
      this.getObjectPermission(nVal);
      if (
        this.objectApi == "cloudccpurchaseorder" ||
        this.objectApi == "cloudccorder" ||
        this.objectApi == "quote" ||
        this.objectApi == "Invoices"
      ) {
        this.$store.commit("setObjectApi", this.objectApi);
        this.$store.commit("setObjId", this.objId);
        this.$store.commit("setPrefix", this.prefix);
        this.getTemplateViewStatus();
      }
    },
    // 监听表格头数据变化，生成统计展示字段数据
    tableAttr(newVal) {
      // 记录列宽，拖动列，只改变当前列的宽度，其他不变

      this.$nextTick(() => {
        if (this.$refs.tablePanel && this.$refs.tablePanel.columns) {
          this.columns = {};
          this.$refs.tablePanel.columns.forEach((item) => {
            if (item.property) {
              this.columns[item.property] = item.realWidth;
            }
          });
        }
      });

      // 默认锁定第一列
      if (newVal && newVal.length > 0) {
        this.tableAttr && this.locked(this.tableAttr[0], true);
      }
      let type = ["c", "N"];
      // c:币种 C:累计汇总 N:数字 Z：公式
      // apiname：使用schemefieldName，否则有可能大小写不一致
      this.typeAry = [];
      newVal.forEach((item) => {
        if (item.schemefieldType === "Z") {
          // 公式
          if (item.expressionType === "c" || item.expressionType === "N") {
            this.typeAry.push({
              type: item.expressionType,
              label: item.nameLabel,
              value: 0,
              apiname: item.schemefieldName,
            });
          }
        }else if (item.schemefieldType === "C" ) {
          if(item.expressionType === "COUNT"){
             // 累计汇总COUNT
            this.typeAry.push({
              type: "N",
              label: item.nameLabel,
              value: 0,
              apiname: item.schemefieldName,
            });
          } else if(item.expressionType === "SUM" && (item.summaryfieldtype === "c" || item.summaryfieldtype === "N")){
            // 累计汇总SUM
            this.typeAry.push({
              type: item.summaryfieldtype,
              label: item.nameLabel,
              value: 0,
              apiname: item.schemefieldName,
            });
          }

        }
        if (type.includes(item.schemefieldType)) {
          // 币种、 数字
          this.typeAry.push({
            type: item.schemefieldType,
            label: item.nameLabel,
            value: 0,
            apiname: item.schemefieldName,
          });
        }
      });
      // 初始化统计数据
      this.handlestatisticsList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.liBox {
  &:hover {
    color: $--color-black;
  }
}
.paginationBox {
  ::v-deep .el-input {
    height: 22px;
    text-align: center;
  }
  ::v-deep .el-input__inner {
    height: 22px;
  }
}

// 表格列拖动时竖线样式
::v-deep .el-table__column-resize-proxy {
  border-left: 1.5px solid $--color-primary;
}
// 列管理器样式
.el-popper {
  padding: 0px !important;
  border: 1px solid #e1e1e1;
  .el-dropdown-menu__item {
    margin: 4px;
    border-radius: 4px 4px 4px 4px;
    color: #080707;
  }
  .el-dropdown-menu__item:hover {
    color: #080707;
    background: #eeeeee;
  }
}

// 分页、统计
::v-deep .flexBox {
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // 表格下边框一直固定效果
  border-top: 1px solid #eeeeee;
  .statisticsBox {
    .spanBox {
      padding-right: 6px;
    }
    .iBox {
      font-size: 16px;
    }
    .iiBox {
      font-style: inherit;
      color: #e1e1e1;
    }
    .apinameBox {
      color: #666666;
      padding: 0 4px;
    }
    .svgBox4:hover {
      background-color: #f3f7ff;
    }
    .svgBox4 {
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      height: 20px;
      width: 20px;
      text-align: center;
      border-radius: 4px;
      .admin_ss {
        width: 14px;
      }
    }
  }
  .el-pagination__jump {
    margin-left: 0;
    // 分页input宽度为50px，父组件中设置100%影响了
    .el-input {
      width: 50px !important;
    }
  }
}
// 处理表格表头样式
::v-deep .el-table__header {
  tr {
    th:first-child {
      border-right: none !important;
    }
    th:nth-child(2) {
      border-left: none !important;
    }
  }
}
// 下拉菜单样式修改
::v-deep .popper__arrow {
  display: none !important;
}
.dialogModel {
  // 弹窗内联编辑防止头部出现一条线
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  // 改变弹窗层级
  ::v-deep .el-dialog__wrapper {
    z-index: 2001 !important;
  }
}
::v-deep .handleCol {
  .el-dialog__header {
    padding: 10px 16px;
  }
  .el-dialog__body {
    padding: 0;
    padding-bottom: 20px;
  }
  .el-dialog__footer {
    padding: 10px 16px;
  }
}
// 内联编辑
.lineBox {
  position: fixed;
  width: 240px;
  top: 10px;
  left: 20px;
  padding: 8px;
  z-index: 2002;
  background: #ffffff;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.12);
  .editBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 4px 0;
    i {
      font-size: 20px;
      padding-left: 8px;
    }
  }
}

// 列表操作按钮
.tableButton {
  margin-top: 4px;
  cursor: pointer;
  width: 16px;
  height: 14px;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}

// 表头锁定按钮
.lockedImg {
  margin-top: 4px;
  width: 11px;
}

.content-main {
  position: relative;
  clear: both;
  height: calc(100% - 40px);
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 0;
  width: calc(100% - 20px);
}

::v-deep .el-progress-bar__outer {
  margin-right: 10px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .cc-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 排序图标
.sortImg {
  height: 16px;
  width: 16px;
  display: inline-block;
}

// 编辑图标样式
.editIconImg {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  height: 12px;
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid $--background-color-hover;
}
// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid $--background-color-hover;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid $--background-color-hover;
}

::v-deep .el-table--border th {
  border-right: 1px solid $--background-color-hover;
}
// 新版表格统一表头高度36px
::v-deep .el-table__header tr th {
  padding: 6px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}
// 表格多选按钮与表头一致
::v-deep .el-table__body {
  .el-checkbox {
    margin-right: 0;
  }
}
.lineBoxEdit {
  margin-left: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}
.admin_s {
  margin: 4px 0 0 0px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table th .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
// 新版表格行统一高度36px
::v-deep .el-table td {
  position: relative;
  padding: 6px 0;
  color: #080707;
  border-bottom: 1px solid $--background-color-hover;
  word-break: break-all;

  .cell {
    min-height: 23px;
    position: relative;

    > span,
    > a {
      width: calc(100% - 1px);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > span > img {
        right: 5px !important;
      }
    }
    .user_box {
      line-height: 23px;
      height: 23px;
      display: flex;
      .headPortrait {
        .logoname {
          font-size: 12px !important;
          line-height: 23px;
        }
      }
      a {
        width: calc(100% - 27px);
        margin-left: 5px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}

::v-deep .el-input__prefix {
  top: 0 !important;
}
</style>
<style lang="scss">
@import "@/style/variables.scss";
// 选中行背景样式
// row-class-name需要设置为全局样式，否则不生效
.el-table .rowBox_chen {
  background-color: $--color-primary-light-06;
}
.table-popover-span {
  .el-checkbox {
    display: block;
  }
}
.pop_small_edit {
  border: 1px solid #e1e1e1 !important;
}
.pop_small_edit ul {
  margin: 0 -12px;
  min-height: 26px;
  padding: 0 4px;
}

.pop_small_edit li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
  border-radius: 4px;
  margin-bottom: 4px;
}
.pop_small_edit li span {
  padding-left: 10px;
}
.pop_small_edit li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.cannotEdit {
  box-sizing: border-box;
  padding-right: 15px;
}
.myDiv {
  width: 100%;
  height: 100%;
}
// 表格滚动错位
.myTable {
  height: 100%;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid #dedcda;
  .el-table--border {
    border: none;
  }
  .el-table {
    overflow: visible;
  }
  .el-table__header-wrapper {
    overflow: hidden;
    position: sticky;
    top: 0px;
    z-index: 10;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
  }
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    height: 0px;
  }
  .el-table__fixed {
    overflow: visible;
  }
  .el-table__fixed-header-wrapper {
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}
</style>
