<template>
  <div class="yi-scrollbar">
    <!-- 外层容器，定义滚动范围 -->
    <div
      class="yi-scrollbar__wrap"
      :style="wrapStyle"
      ref="wrap"
      @scroll="handleScroll"
    >
      <!-- 容器通过插槽引入 -->
      <slot></slot>
    </div>
    <!-- 模拟滑轨和滑块 -->
    <div
      v-if="thumbVisible"
      class="yi-scrollbar__track"
      :class="{ visible: mouseDown }"
      @mousedown.self="mousedownTrack"
    >
      <div
        class="yi-scrollbar__thumb"
        @mousedown="mousedownThumb"
        :style="thumbStyle"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // 滑块对象
      thumb: {
        // 滑块的宽度
        width: "0%",
        // 滑块的位置
        translateX: "0%",
      },
      // 容器宽度
      wrapWidth: 0,
      // 内容宽度
      viewWidth: 0,
      // 鼠标是否按下
      mouseDown: false,
      // 缩放参数
      scale: 0,
      // 初始化
      init: {
        // 鼠标按下时的位置
        clientX: 0,
        // 滚动距离
        scrollLeft: 0,
      },
      // 是否显示滑轨
      thumbVisible: false,
      // 滑块高度
      scrollBarHeight: 0,
    };
  },
  watch: {
    dataList() {
      setTimeout(() => {
        this.initV();
      }, 1000);
    },
  },
  computed: {
    // 计算滑块样式
    thumbStyle: function () {
      return {
        width: this.thumb.width,
        transform: `translateX(${this.thumb.translateX})`,
      };
    },
    // 计算外层容器样式
    wrapStyle: function () {
      return {
        marginRight: `-${this.scrollBarHeight}px`,
        marginBottom: `-${this.scrollBarHeight}px`,
      };
    },
  },

  mounted() {
    setTimeout(() => {
      this.initV();
    }, 1500);
    window.addEventListener("resize", this.initV);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.initV);
    document.removeEventListener("mouseup", this.mouseupThumb, false);
    this.$refs.wrap.removeEventListener("scroll", this.handleScroll, false);
    document.removeEventListener("mouseup", this.mouseupThumb, false);
  },

  methods: {
    /**
     * initV:初始化滚动条，计算滚动条宽高，监听表格滚动
     */
    initV() {
      this.$refs.wrap = document.querySelector(
        ".myTable .el-table__body-wrapper"
      );
      if(!this.$refs.wrap)return;
      // 监听表格滚动
      this.$refs.wrap.addEventListener("scroll", this.handleScroll, false);
      this.scrollBarHeight = this.getScrollbarWidth();
      this.wrapWidth = this.$refs.wrap.clientWidth;
      this.viewWidth = document.querySelector(
        ".myTable .el-table__body-wrapper .el-table__body"
      ).scrollWidth;
      // 只有在内层容器大于外层容器高度时才显示滚动条
      this.thumbVisible = this.viewWidth - this.wrapWidth > 8;
      // 
      this.calcThumbHeight();
    },
    // 获取滚动条宽度
    getScrollbarWidth() {
      const outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.position = "absolute";
      outer.style.top = "-9999px";
      document.body.appendChild(outer);

      const widthNoScroll = outer.offsetWidth;
      outer.style.overflow = "scroll";

      const inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);

      const widthWithScroll = inner.offsetWidth;
      outer.parentNode.removeChild(outer);
      // 
      // 
      return widthNoScroll - widthWithScroll;
    },

    // 计算滚动条滑块宽度
    calcThumbHeight() {
      this.thumb.width = (this.wrapWidth / this.viewWidth) * 100 + "%";
      // 滚动条滑块宽度
      const thumbWidth = (this.wrapWidth * this.wrapWidth) / this.viewWidth;
      // scrollTop 最大值 / 滑块可以滚动的距离，这样计算出滑块滑动距离后乘以该系数就能得到 scrollTop 值
      // 滚动条缩放比例
      this.scale =
        (this.viewWidth - this.wrapWidth) / (this.wrapWidth - thumbWidth);
    },

    // 监听滚动事件重置滚动条的X轴偏移量
    handleScroll(e) {
      this.thumb.translateX =
        (e.target.scrollLeft / this.wrapWidth) * 100 + "%";
    },

    // 监听鼠标在滑块上点击的事件
    mousedownThumb(e) {
      e.stopImmediatePropagation();
      this.mouseDown = true;

      // 记录鼠标点击初始偏移量
      this.init.clientX = e.clientX;
      this.init.scrollLeft = this.$refs.wrap.scrollLeft;

      // 

      // 事件添加到 document 上是为了拖拽滑块的时候鼠标移出内容区域也能响应事件
      document.addEventListener("mousemove", this.mousemoveThumb, false);
      document.addEventListener("mouseup", this.mouseupThumb, false);

      // 在滚动的时候禁止选中文本
      document.onselectstart = () => false;
    },

    // 监听鼠标拖拽事件
    mousemoveThumb(e) {
      if (!this.mouseDown) return;
      // 
      // 
      // 
      // 当前鼠标位置减去初始鼠标位置得到鼠标拖拽的偏移量
      const offset = e.clientX - this.init.clientX;
      // 鼠标拖拽偏移量乘以上面计算的系数得出 scrollTop
      const scrollLeft = offset * this.scale;
      // 
      // scrollTop 一定要加上初始 scrollTop
      this.$refs.wrap.scrollLeft = this.init.scrollLeft + scrollLeft;
    },

    // 监听鼠标离开事件
    mouseupThumb() {
      this.mouseDown = false;
      this.init.scrollLeft = 0;
      // 这里要移除拖拽事件，不然鼠标离开了有可能拖拽事件还在执行
      document.removeEventListener("mousemove", this.mousemoveThumb, false);
      document.onselectstart = null;
    },

    // 监听滑轨点击事件，直接滚动到指定位置
    mousedownTrack(e) {
      // 鼠标在元素内偏移量为鼠标距离顶部的距离减去元素顶部偏移
      const offset = e.clientX - e.target.getBoundingClientRect().left;
      // 根据鼠标点击位置偏移量计算 scrollTop
      const scrollLeft =
        (offset / this.wrapWidth) * this.viewWidth - this.wrapWidth / 2;
      this.$refs.wrap.scrollLeft = scrollLeft;
    },
  },
};
</script>

<style lang="scss" scoped>
.yi-scrollbar {
  overflow: hidden;
  position: relative;
  // 鼠标上浮展示滚动条
  &:hover {
    .yi-scrollbar__track {
      opacity: 1;
    }
  }
}

.yi-scrollbar__wrap {
  overflow: auto;
  height: 100%;
  // 滚动条不占位置
  // padding-bottom: 10px;
  width: 100%;
}
.yi-scrollbar__wrap::-webkit-scrollbar {
  display: none;
}
.yi-scrollbar__track {
  position: absolute;
  left: 2px;
  bottom: 0px;
  right: 2px;
  height: 10px;
  border-radius: 10px;
  z-index: 1000;
  // 鼠标上浮展示滚动条
  opacity: 0;
  transition: opacity 120ms ease-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
  }
}

.yi-scrollbar__thumb {
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 100%;
  border-radius: 8px;
}
</style>