<template>
  <div class="sortBox">
    <div class="inputBox">
      <el-input
        style="width: 94%"
        :placeholder="$t('c83')"
        v-model="colVal"
        clearable
        @input="querySearchAsync"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>
    <div class="mainBox" v-infinite-scroll="getUnselectedList" :infinite-scroll-distance="10">
      <draggable
        ref="dragBox"
        :disabled="colVal.trim()"
        v-model="selectedList"
        handle=".move"
        filter=".unmover"
        dataIdAttr="data-id"
        forceFallback="true"
        animation="150"
        @start="onStart"
        @end="onEnd"
      >
        <transition-group>
          <div
            class="move flexBox"
            v-for="element in selectedList"
            :key="element.id"
            :data-id="element.label"
          >
            <!-- 上浮svg -->
            <div>
              <svg
                class="SvgBox marginBox"
                aria-hidden="true"
                v-show="element.id === moveVal"
                @mouseout="changSvgOut"
              >
                <use href="#icon-a-Click-Sort"></use>
              </svg>
              <!-- 离开svg -->
              <svg
                class="SvgBox marginBox"
                aria-hidden="true"
                v-show="element.id !== moveVal"
                @mouseover="changSvgOver(element.id)"
              >
                <use href="#icon-a-Normal-Sort"></use>
              </svg>
            </div>
            <el-checkbox
              class="unmover marginBox"
              :disabled="allselectedList.length <= 1"
              v-model="element.isCheck"
              @change="changeCheck(element)"
            ></el-checkbox>
            <div class="spanOver">{{ element.label }}</div>
          </div>
        </transition-group>
      </draggable>
      <transition-group>
        <div class="flexBox" v-for="item in unselectedList.slice(0,(unIndex+1)*30)" :key="item.id">
          <span class="spanBox marginBox"></span>
          <el-checkbox
            class="marginBox"
            :disabled="allselectedList.length >= 15"
            v-model="item.isCheck"
            @change="changeCheck(item)"
          ></el-checkbox>
          <div class="spanOver">{{ item.label }}</div>
        </div>
      </transition-group>
      <!-- 暂无数据 -->
      <div v-show="selectedList.length===0&&unselectedList.length===0" class="noData">{{$t('vue_label_SMS_nodata')}}</div>
    </div>
  </div>
</template>
<script>
/**
 * 表格管理列组件：列排序、选中展示
 */
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: ["unselectedFieldList", "selectedFieldList"],
  //  unselectedFieldList 未选中的表头
  // selectedFieldList 选中的表头
  data() {
    return {
      // 未选择字段展示起始索引，解决搜索性能问题
      unIndex:0,
      moveVal: "", // 当前上浮的行
      colVal: "", // 搜索列内容,当有内容时，禁止拖动
      FieldList: [], // 所有字段
      allselectedList: [], // 所有选中字段
      allunselectedList: [], // 所有未选中字段
      selectedList: [], // 展示选中字段
      unselectedList: [], // 展示未选中字段
    };
  },
  created() {
    this.initData();
  },
  watch: {
    selectedFieldList() {
      this.initData();
    },
    allselectedList(newVal) {
      // 实时更新保存参数
      let selecteId = [];
      newVal.forEach((item) => {
        selecteId.push(item.id);
      });
      this.$emit("selFun", selecteId.join(","));
    },
  },
  methods: {
    // mainBox滚动到底部加载更多
    getUnselectedList(){
      if(this.unselectedList.length>(this.unIndex+1)*30){
        this.unIndex++
      }
      
    },
    // 初始化数据
    initData() {
      // 选中的字段
      this.selectedList = [];
      this.unselectedList = [];
      this.selectedFieldList.forEach((item) => {
        let obj = Object.assign({}, item);
        obj.isCheck = true;
        this.selectedList.push(obj);
      });
      this.allselectedList = [...this.selectedList];
      // 未选中字段
      this.unselectedFieldList.forEach((item) => {
        let obj = Object.assign({}, item);
        obj.isCheck = false;
        this.unselectedList.push(obj);
      });
      this.allunselectedList = [...this.unselectedList];
      //  全部字段
      this.FieldList = [...this.selectedList, ...this.unselectedList];
    },
    /**
     * 父组件调用
     * 传递给父组件保存的id
     */
    curGroupLists() {
      let selecteArr = [];
      this.allselectedList &&
        this.allselectedList.forEach((val) => {
          selecteArr.push(val.id);
        });
      this.$emit("memberFun", selecteArr.join(","));
    },

    // 搜索字段
    querySearchAsync(nval) {
      
      //初始化
      if (nval.trim() !== "") {
        this.selectedList=this.allselectedList.filter(item=>{
          return item.label &&
            item.label.toLowerCase().indexOf(nval.trim().toLowerCase()) != -1
        })
        this.unselectedList=this.allunselectedList.filter(item=>{
          return item.label &&
            item.label.toLowerCase().indexOf(nval.trim().toLowerCase()) != -1
        })
      } else {
        //   无搜索
        this.selectedList = [...this.allselectedList];
        this.unselectedList = [...this.allunselectedList];
      }
        // 初始化展示索引
        this.unIndex=0
    },
    // 上浮
    changSvgOver(val) {
      this.moveVal = val;
    },
    // 离开
    changSvgOut() {
      this.moveVal = NaN;
    },
    // 拖动开始
    onStart() {},
    // 拖动结束
    onEnd() {
      // 同步allselectedList和selectedList顺序
      this.allselectedList = [...this.selectedList];
    },
    // 多选框绑定值变化
    changeCheck(item) {
      if (item.isCheck) {
        // 删除unselectedList中的项
        this.unselectedList.forEach((item1, index) => {
          if (item.id === item1.id) {
            this.unselectedList.splice(index, 1);
          }
        });
        // 同步allunselectedList
        this.allunselectedList.forEach((item1, index) => {
          if (item.id === item1.id) {
            this.allunselectedList.splice(index, 1);
          }
        });
        // 添加到selectedList中
        this.selectedList.push(item);
        // 同步allselectedList
        this.allselectedList.push(item);
        // 最多选中10条
        if (this.allselectedList.length >= 15) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
          );
        }
      } else {
        this.selectedList.forEach((item1, index) => {
          if (item.id === item1.id) {
            this.selectedList.splice(index, 1);
          }
        });
        this.allselectedList.forEach((item1, index) => {
          if (item.id === item1.id) {
            this.allselectedList.splice(index, 1);
          }
        });
        if (this.allselectedList.length <= 1) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_notice_atleast1field")
          );
        }
        this.allunselectedList.push(item);
        this.unselectedList.push(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sortBox {
  overflow: hidden;
  .mainBox {
    height: 64vh;
    width: calc(100% + 10px);
    overflow-y: scroll;
    overflow: auto;
    .noData{
      height: 80px;
      line-height: 80px;
      text-align: center;
      margin-left: -10px;
    }
  }
  .inputBox {
    padding: 10px 0;
    margin-right: 10px;
    display: flex;
    justify-content: center;
  }

  .move {
    background: #f5f5f5;
    border-radius: 4px 4px 4px 4px;
    cursor: move;
  }
  .move:hover {
    background: #f3f7ff;
    cursor: move;
  }
  .SvgBox {
    width: 15px;
    height: 20px;
    cursor: pointer;
  }
  .spanOver {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 460px;
  }
  .flexBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 20px;
    margin: 4px;
    // margin: 4px 14px 4px 4px;
  }
  .spanBox {
    display: inline-block;
    width: 15px;
    height: 20px;
  }
  .marginBox {
    margin-right: 10px !important;
  }
}
</style>